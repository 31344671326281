import { useState, useEffect } from 'react';
import { IconButton, Row } from '@livechat/ui-kit';
import { TextField } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useStyles } from './HelperTextStyles';
import excited from '../images/excited.png';
import excitedActive from '../images/excitedActive.png';
import happy from '../images/happyGrey.png';
import happyActive from '../images/happyActive.png';
import neutral from '../images/neutralGrey.png';
import neutralActive from '../images/neutralActive.png';
import sad from '../images/sadGrey.png';
import sadActive from '../images/sadActive.png';
import angry from '../images/angryGrey.png';
import angryActive from '../images/angryActive.png';
import {
  BACK_TO_CHAT_ENG,
  BACK_TO_CHAT_ESP,
  ENTER_EMAIL_ADDRESS,
  ENTER_EMAIL_ADDRESS_ESP,
  ERROR_MESSAGE_TRANSCRIPT_ENG,
  ERROR_MESSAGE_TRANSCRIPT_ESP,
  HAVE_MORE_TO_SAY_ENG,
  HAVE_MORE_TO_SAY_ESP,
  SEND_CHAT_TRANSCRIPT_ENG,
  SEND_CHAT_TRANSCRIPT_ESP,
  SUBMIT_FEEDBACK_ENG,
  SUBMIT_FEEDBACK_ESP,
} from '../../../Constants';

const AgentFeedback = (props) => {
  const classes = useStyles();
  const {
    continueChatWithAgent,
    endChatFromAgentFeedback,
    englishBot,
    wrapEmail,
    awsConnectAgentName,
    liveAgentAvailable,
    continueChat,
  } = props;
  const [excitedImage, setExcitedImage] = useState(excited);
  const [happyImage, setHappyImage] = useState(happy);
  const [neutralImage, setNeutralImage] = useState(neutral);
  const [sadImage, setSadImage] = useState(sad);
  const [angryImage, setAngryImage] = useState(angry);
  const [excitedPress, setExcitedPress] = useState(false);
  const [happyPress, setHappyPress] = useState(false);
  const [neutralPress, setNeutralPress] = useState(false);
  const [sadPress, setSadPress] = useState(false);
  const [angryPress, setAngryPress] = useState(false);
  const [checked, setChecked] = useState(false);
  const [feedbackReaction, setFeedbackReaction] = useState('');
  const CHARACTER_LIMIT = 160;
  const [comment, setComment] = useState({
    summary: '',
  });

  const handleChange = (summary) => (event) => {
    setComment({ ...comment, [summary]: event.target.value });
  };
  const [emailValid, setEmailValid] = useState(wrapEmail !== '');
  const [emailValue, setEmailValue] = useState(wrapEmail !== '' ? wrapEmail : '');
  const defaultContent = englishBot
    ? `How would you rate ${awsConnectAgentName} support?`
    : `¿Cómo calificas el apoyo de ${awsConnectAgentName}?`;
  const defaultEndChat = englishBot ? SUBMIT_FEEDBACK_ENG : SUBMIT_FEEDBACK_ESP;
  const defaultContinueChat = englishBot ? BACK_TO_CHAT_ENG : BACK_TO_CHAT_ESP;
  const emailInputPlaceholder = englishBot ? ENTER_EMAIL_ADDRESS : ENTER_EMAIL_ADDRESS_ESP;
  const commentInputPlaceholder = englishBot ? HAVE_MORE_TO_SAY_ENG : HAVE_MORE_TO_SAY_ESP;
  const checkboxLabel = englishBot ? SEND_CHAT_TRANSCRIPT_ENG : SEND_CHAT_TRANSCRIPT_ESP;
  const errorMessage = englishBot ? ERROR_MESSAGE_TRANSCRIPT_ENG : ERROR_MESSAGE_TRANSCRIPT_ESP;
  const checkboxClickHandler = () => {
    setChecked(!checked);
  };
  const emailHandler = (e) => {
    if (
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i.test(
        e.target.value
      )
    ) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
    setEmailValue(e.target.value);
  };

  const emailOpacity = {
    opacity: '0.5',
    pointerEvents: 'none',
  };
  const eemailErrorMessageDisplay = {
    margin: '0 auto',
    color: '#DF0000',
    marginTop: '5px',
  };
  const emailErrorMessageNoDisplay = {
    display: 'none',
  };
  const emailOpacityFull = {
    opacity: '1',
  };
  useEffect(() => {
    const wrapupBtns = document.getElementsByClassName('wrapupTwoBtn');
    if (wrapupBtns && document.getElementsByClassName('wrapupTwoBtn').length > 0) {
      wrapupBtns[0].firstChild.focus();
    }
  }, []);
  const handleRating = (value) => {
    setFeedbackReaction(value);
    switch (value) {
      case 'excited':
        setExcitedImage(excitedActive);
        setHappyImage(happy);
        setNeutralImage(neutral);
        setSadImage(sad);
        setAngryImage(angry);
        setExcitedPress(true);
        setHappyPress(false);
        setNeutralPress(false);
        setSadPress(false);
        setAngryPress(false);
        break;
      case 'happy':
        setExcitedImage(excited);
        setHappyImage(happyActive);
        setNeutralImage(neutral);
        setSadImage(sad);
        setAngryImage(angry);
        setExcitedPress(false);
        setHappyPress(true);
        setNeutralPress(false);
        setSadPress(false);
        setAngryPress(false);
        break;
      case 'neutral':
        setExcitedImage(excited);
        setHappyImage(happy);
        setNeutralImage(neutralActive);
        setSadImage(sad);
        setAngryImage(angry);
        setExcitedPress(false);
        setHappyPress(false);
        setNeutralPress(true);
        setSadPress(false);
        setAngryPress(false);
        break;
      case 'sad':
        setExcitedImage(excited);
        setHappyImage(happy);
        setNeutralImage(neutral);
        setSadImage(sadActive);
        setAngryImage(angry);
        setExcitedPress(false);
        setHappyPress(false);
        setNeutralPress(false);
        setSadPress(true);
        setAngryPress(false);
        break;
      case 'angry':
        setExcitedImage(excited);
        setHappyImage(happy);
        setNeutralImage(neutral);
        setSadImage(sad);
        setAngryImage(angryActive);
        setExcitedPress(false);
        setHappyPress(false);
        setNeutralPress(false);
        setSadPress(false);
        setAngryPress(true);
        break;
      default:
        break;
    }
  };
  const endChatHandler = () => {
    endChatFromAgentFeedback(comment.summary, feedbackReaction);
  };
  return (
    <div
      className="wrapup-containerTwo"
      style={{
        textAlign: 'center',
      }}
    >
      <div className={classes.agent_container}>
        <div className="endchatHeading">{defaultContent}</div>
        <div className="agentWrapupContainer">
          <div className="wrapupTwoBtn">
            <div
              className={classes.agent_padding}
              onClick={() => handleRating('excited')}
              data-testid="handleRating_excited_testid"
              tabIndex="102"
              aria-label="excited emoji"
              aria-pressed={excitedPress}
            >
              <img alt="excited" className="smileyImg" src={excitedImage} />
            </div>
          </div>
          <div className="wrapupTwoBtn">
            <div
              className={classes.agent_padding}
              onClick={() => handleRating('happy')}
              data-testid="handleRating_happy_testid"
              tabIndex="102"
              aria-label="happy emoji"
              aria-pressed={happyPress}
            >
              <img alt="happy" className="smileyImg" src={happyImage} />
            </div>
          </div>
          <div className="wrapupTwoBtn">
            <div
              className={classes.agent_padding}
              onClick={() => handleRating('neutral')}
              data-testid="handleRating_neutral_testid"
              tabIndex="102"
              aria-label="neutral emoji"
              aria-pressed={neutralPress}
            >
              <img alt="neutral" className="smileyImg" src={neutralImage} />
            </div>
          </div>
          <div className="wrapupTwoBtn">
            <div
              className={classes.agent_padding}
              onClick={() => handleRating('sad')}
              data-testid="handleRating_sad_testid"
              tabIndex="102"
              aria-label="sad emoji"
              aria-pressed={sadPress}
            >
              <img alt="sad" className="smileyImg" src={sadImage} />
            </div>
          </div>
          <div className="wrapupTwoBtn">
            <div
              className={classes.agent_padding}
              onClick={() => handleRating('angry')}
              data-testid="handleRating_angry_testid"
              tabIndex="102"
              aria-label="angry emoji"
              aria-pressed={angryPress}
            >
              <img alt="angry" className="smileyImg" src={angryImage} />
            </div>
          </div>
        </div>
        <div className={classes.agent_feedback}>
          <TextField
            inputProps={{
              maxLength: CHARACTER_LIMIT,
              tabIndex: '102',
              'data-testid': 'textFieldTestid',
            }}
            multiline="true"
            maxRows="3"
            minRows="3"
            value={comment.summary}
            helperText={`${comment.summary.length} of ${CHARACTER_LIMIT}`}
            onChange={handleChange('summary')}
            // data-testid="textFieldTestid"
            placeholder={commentInputPlaceholder}
            variant="outlined"
          />
        </div>
        <div className={classes.agent_marginall}>
          <label className="checkbox-label" htmlFor="consent2">
            <input
              type="checkbox"
              name="consent2"
              id="consent2"
              tabIndex="102"
              checked={checked}
              onChange={checkboxClickHandler}
            />
            <span
              style={{ marginLeft: '0px' }}
              data-testid="checkbox_testid"
              className="checkbox-custom rectangular"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  checkboxClickHandler();
                }
              }}
            />
            <span className="checkbox-label-text">{checkboxLabel}</span>
          </label>
          {checked && (
            <div>
              {' '}
              <input
                id="email-field2"
                className="email-field2"
                type="text"
                placeholder={emailInputPlaceholder}
                tabIndex="102"
                value={emailValue}
                data-testid="email_field2_testid"
                onChange={emailHandler}
              />
              <div style={emailValid ? emailErrorMessageNoDisplay : eemailErrorMessageDisplay}>
                <Row align="center" className={classes.agent_row}>
                  <span>{errorMessage}</span>
                </Row>
              </div>
            </div>
          )}
        </div>
        <div
          className="wrapup-btn"
          style={{
            width: '100%',
            margin: '0 0 25px 0',
          }}
        >
          {liveAgentAvailable ? (
            <IconButton
              onClick={continueChatWithAgent}
              data-testid="continueChatWithAgent_id"
              tabIndex="102"
            >
              {defaultContinueChat}
            </IconButton>
          ) : (
            <IconButton onClick={continueChat} tabIndex="102" data-testid="continueChat_id">
              {defaultContinueChat}
            </IconButton>
          )}
          <IconButton
            className="end-btn"
            onClick={endChatHandler}
            data-testid="endChat_testid"
            tabIndex="102"
            style={checked && !emailValid ? emailOpacity : emailOpacityFull}
          >
            {defaultEndChat}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

AgentFeedback.propTypes = {
  englishBot: PropTypes.bool,
  continueChatWithAgent: PropTypes.func,
  endChatFromAgentFeedback: PropTypes.func,
  wrapEmail: PropTypes.bool,
  awsConnectAgentName: PropTypes.string,
  liveAgentAvailable: PropTypes.bool,
  continueChat: PropTypes.func,
};

export default AgentFeedback;
