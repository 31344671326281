import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  ENG: {
    translation: {
      Chat: 'Chat',
      Yes: 'Yes',
      No: 'No',
      "I'll skip this step": "I'll skip this step",
      'Enter account number': 'Enter account number',
      'Sure, tell us what you need!': 'Sure, tell us what you need!',
      'Did you get what you were looking for?': 'Did you get what you were looking for?',
      'By switching the language, chat history will be lost. Do you want to continue in English?':
        'Al cambiar el idioma, se perderá el historial de chat. ¿Quieres continuar en inglés?',
      'Sure! Lets continue.': 'Sure! Lets continue.',
      'And finally, your account number (this is optional)':
        'And finally, your account number (this is optional)',
      'Need help with anything else!': 'Need help with anything else!',
      'Want to continue chat?': 'Want to continue chat?',
      'End Chat': 'End Chat',
      'Type a message': 'Type a message',
      LangOptionYes: 'Sí',
      LangOptionNo: 'No',
      'Great! And your mobile number please:': 'Great! And your mobile number please:',
      'Thanks. I just need your Email:': 'Thanks. I just need your Email:',
      'Enter your message': 'Enter your message',
      'Choose end date': 'Choose end date',
      'Choose start date': 'Choose start date',
      'Payoff Statement Request': 'Payoff Statement Request',
      'We will be ready to deliver your Payoff Statement within the next 5 business days, after you submit the request.':
        'We will be ready to deliver your Payoff Statement within the next 5 business days, after you submit the request.',
      'Request Details': 'Request Details',
      'About you': 'About you',
      Consent: 'Consent',
      // Modal step One
      'Let’s get started! What is the purpose of this request?*':
        'Let’s get started! What is the purpose of this request?*',
      'I am refinancing my property': 'I am refinancing my property',
      'I am in a buying/selling process': 'I am in a buying/selling process',
      'I will make the last payment': 'I will make the last payment',
      Other: 'Other',
      'Personal purpose': 'Personal purpose',
      'Other, specify': 'Other, specify',
      'Choose the specified Payoff date*:': 'Choose the specified Payoff date*:',
      'Effective Date : *': 'Effective Date : *',
      'Effective Date : ': 'Effective Date : ',
      'Select Day in Effective Date dropdown Required':
        'Select Day in Effective Date dropdown Required',
      DD: 'DD',
      'Select Month in Effective Date dropdown Required':
        'Select Month in Effective Date dropdown Required',
      MMM: 'MMM',
      'Select Year in Effective Date dropdown Required':
        'Select Year in Effective Date dropdown Required',
      YYYY: 'YYYY',
      USA: 'USA',
      VI: 'VI',
      'Date Picker': 'Date Picker',
      'To prevent your Payoff Statement from expiring during the cancellation process, we recommend that you choose an effective date greater than 10 days from today.':
        'To prevent your Payoff Statement from expiring during the cancellation process, we recommend that you choose an effective date greater than 10 days from today.',
      'Upload your document here': 'Upload your document here',
      'More Info': 'More Info',
      Footer: '© 2021 orientalbank.com. All Rights Reserved. FDIC Member.',
      // Modal step two
      'Who will receive the Payoff Statement?*': 'Who will receive the Payoff Statement?*',
      'Me, I am the owner of the loan': 'Me, I am the owner of the loan',
      'Another person who is not the owner of the loan':
        'Another person who is not the owner of the loan',
      'Perfect, tell us about yourself:': 'Perfect, tell us about yourself:',
      'Give both information':
        'Perfect, give us your information and of the person you authorize to receive the Payoff Statement of your loan:',
      Required: 'Required',
      'First Name': 'First Name',
      'Middle Initial': 'Middle Initial',
      'Second Last Name': 'Second Last Name',
      'Last Name': 'Last Name',
      'Mother`s Last Name': 'Mother`s Last Name',
      'Loan Number': 'Loan Number',
      'Enter loan number': 'Enter loan number',
      Phone: 'Phone',
      'Mobile Number': 'Mobile Number',
      Mobile: 'Mobile',
      'Alternate Phone': 'Alternate Phone',
      Relationship: 'Relationship',
      'Aria Label Relationship': 'Select Person Relationship Required',
      'My Realtor': 'My Realtor',
      'My Lawyer': 'My Lawyer',
      'License Number': 'License Number',
      'Enter license number': 'Enter license number',
      'Area Code': 'Area Code',
      'Date of Birth': 'Date of Birth',
      Jan: 'Jan',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      May: 'May',
      Jul: 'Jul',
      Jun: 'Jun',
      Aug: 'Aug',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dec',
      'Information of the person you authorize':
        'Now, enter the information of the person you authorize to receive the Payoff Statement. By completing this section you are authorizing Oriental Bank to share the information included in your Mortgage Payoff Statement with the third party identified in this form.',
      'Date Of Birth Required Select Day in Date Of Birth dropdown Required':
        'Date Of Birth Required Select Day in Date Of Birth dropdown Required',
      'Select Month in Date Of Birth dropdown Required':
        'Select Month in Date Of Birth dropdown Required',
      'Select Year in Date Of Birth dropdown Required':
        'Select Year in Date Of Birth dropdown Required',
      'Aria Label Country': 'Country Required Choose your country dropdown menu button',
      'Area Code Required': 'Area Code Required',
      'Aria Label State': 'State Required Choose your state dropdown menu button',
      'Aria Label City': 'City Required Choose your city dropdown menu button',
      'Now, let us know your email to send you the document':
        'Now, let us know your email to send you the document',
      'By email': 'By email',
      'By mail': 'By mail',
      'Email Address': 'Email Address',
      'Social Security Number': 'Social Security Number',
      'Enter social security number': 'Enter social security number',
      'Enter first name': 'Enter first name',
      'Enter middle initial': 'Enter middle initial',
      'Enter last name': 'Enter last name',
      'Enter phone number': 'Enter phone number',
      'Enter second last name': 'Enter second last name',
      'Enter email address': 'Enter email address',
      'Enter address details': 'Enter address details',
      'Company Name': 'Company Name',
      'Enter company name': 'Enter company name',
      'Specify Relationship Type': 'Specify Relationship Type',
      'Enter relationship type': 'Enter relationship type',
      '* Invalid Date': '* Invalid Date',
      Back: 'Back',
      Select: 'Select',
      Continue: 'Continue',
      'Please select country': 'Please select country',
      'Please select country and state': 'Please select country and state',
      'Home Address': 'Home Address',
      'Address Line 1': 'Address Line 1',
      'Address Line 2': 'Address Line 2',
      Country: 'Country',
      State: 'State',
      City: 'City',
      'Zip Code': 'Zip Code',
      'Enter address 1': 'Enter address 1',
      'Enter address 2': 'Enter address 2',
      'Choose your country': 'Choose your country',
      'Choose your state': 'Choose your state',
      'Choose your city': 'Choose your city',
      'Enter Zip Code': 'Enter Zip Code',
      'Provide your consent': 'Provide your consent',
      Send: 'Send',
      'This application has a $10.00 cost. I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585.':
        'This application has a $10.00 cost. I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585.',
      'The first payoff satement request and any update or correction to that original request will be issued at no cost to the client. Any additional payoff statement requests within a six (6) month period will be charged at $25.00.  I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585.':
        'The first payoff satement request and any update or correction to that original request will be issued at no cost to the client. Any additional payoff statement requests within a six (6) month period will be charged at $25.00.  I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585.',
      'I consent to receive this payoff statement electronically. This consent applies solely and exclusively to the sending of this request. I acknowledge that I have the right to withdraw this electronic consent at any time and receive a paper copy of the payoff statement. To withdraw consent, request a copy of the document and change the email address, I must contact 787.767.8585 or send an email to servicio.hipotecas@orientalbank.com. We will send your payoff statement in PDF format. A PDF reader program may be required to view and print PDF files.':
        'I consent to receive this payoff statement electronically. This consent applies solely and exclusively to the sending of this request. I acknowledge that I have the right to withdraw this electronic consent at any time and receive a paper copy of the payoff statement. To withdraw consent, request a copy of the document and change the email address, I must contact 787.767.8585 or send an email to servicio.hipotecas@orientalbank.com. We will send your payoff statement in PDF format. A PDF reader program may be required to view and print PDF files.',
      // Error Modal
      '* Invalid format': '* Invalid format',
      '* Mandatory Field': '* Mandatory Field',
      '* Social security number should have 9 digits':
        '* Social security number should have 9 digits',
      '* Areacode should have 3 digits': '* Areacode should have 3 digits',
      '* Mobile number should have 7 digits': '* Mobile number should have 7 digits',
      '* Phone number should have 7 digits': '* Phone number should have 7 digits',
      '* You must be 18 years or older': '* You must be 18 years or older',
      '* Invalid email': '* Invalid email',
      '* Date should not be greater than 30 days': '* Date should not be greater than 30 days',
      '* Date should not be in the past': '* Date should not be in the past',
      '* Account number should have atleast 10 digits':
        '* Account number should have atleast 10 digits',
      // Thank you page
      'we are done': 'we are done',
      'Done message':
        'Your Payoff Statement Request was received and will be sent within the next 5 business days. Your reference number is CM-12345.',
      'Close and return to chat': 'Close and return to chat',
      'Download Payoff Form': 'Download Payoff Form',
      'Thank You Header': 'Thank You Header',
      'Download button': 'Download Payoff Form Button',
      'Go to orientalbank.com': 'Go to orientalbank.com',
      'Go to site button': 'Go to orientalbank.com button',
      EndLiveAgentMessage: 'Chat with Live Agent Ended',
      // Verify Form
      'Check in as customer': 'Check in as customer',
      'Form Desc':
        'We want to protect your account, tell us more about you. This information will be sent exclusively to the live agent and will not be shown in the chat history',
      'Full Name': 'Full Name',
      // "Date of Birth": "Date of Birth",
      'Last 4 social security number': 'Last 4 social security number',
      'Account number': 'Account number',
      // "Send": "Send",
      'Continue as guest': 'Continue as guest',
      'Effective Date': 'Effective Date',
      'Having reached required age (60 years old)': 'Having reached required age (60 years old)',
      'Personal Matters': 'Personal Matters',
      Unemployment: 'Unemployment',
      Disability: 'Disability',
      'Purchase or Construction of First Home': 'Purchase or Construction of First Home',
      'Purchase of a computer for the use of a dependent':
        'Purchase of a computer for the use of a dependent',
      'Death of Participant': 'Death of Participant',
      'Payment of debt for alimony': 'Payment of debt for alimony',
      'Repair or Construction of main residence due to Fortuitous Causes':
        'Repair or Construction of main residence due to Fortuitous Causes',
      'University studies of direct dependents': 'University studies of direct dependents',
      'Treatment of severe, cronic, degenerative or terminal desease':
        'Treatment of severe, cronic, degenerative or terminal desease',
      'Transfer due to divorce': 'Transfer due to divorce',
      'Foreclousure due to reduction of income': 'Foreclousure due to reduction of income',
      'Pay for the costs of post secondary studies of the beneficiary':
        'Pay for the costs of post secondary studies of the beneficiary',
      'Reimbursement due to death or disability of the beneficiary':
        'Reimbursement due to death or disability of the beneficiary',
      "Reimbursement of funds due to the beneficuary's decision":
        "Reimbursement of funds due to the beneficuary's decision",
      'Please select': 'Please select',
      'Please Upload Documents': 'Please Upload Documents',
      'Select Files': 'Select Files',
      'Upload a maximum of 5 documents of 10MB each':
        'Upload a maximum of 5 documents of 10MB each',
      'Uploaded file exceeds the max size': 'Uploaded file exceeds the max size',
      'Remove All': 'Remove All',
      'Undo Removed File': 'Undo Removed File',
      'Undo Removed {num} Files': 'Undo Removed {num} Files',
      Confirm: 'Confirm',
      Cancel: 'Cancel',
      'Please, Wait while we process your request.': 'Please, Wait while we process your request.',
      'Supported file type': 'Supported file type',
    },
  },
  ESP: {
    translation: {
      Chat: 'Chat',
      Yes: 'Sí',
      No: 'No',
      "I'll skip this step": 'Omitir este paso',
      'Enter account number': 'Entrar número de cuenta',
      'Sure, tell us what you need!': '¡Por supuesto! ¿Qué necesitas?',
      'Did you get what you were looking for?': '¿Conseguiste lo que buscabas?',
      'By switching the language, chat history will be lost. Do you want to continue in English?':
        'By switching the language, chat history will be lost. Do you want to continue in Spanish?',
      'And finally, your account number (this is optional)': 'Número de cuenta (opcional)',
      'Sure! Lets continue.': '¡Por supuesto! Continuemos.',
      'Need help with anything else!': 'Estoy aquí para ayudarte. ¿Necesitas algo más?',
      'End Chat': 'Cerrar Chat',
      'Want to continue chat?': '¿Quieres seguir charlando?',
      'Type a message': 'Escribe un mensaje',
      LangOptionNo: 'No',
      LangOptionYes: 'Yes',
      'Great! And your mobile number please:': 'Número de celular',
      'Thanks. I just need your Email:': 'Correo electrónico',
      'Enter your message': 'Ingresa tu mensaje',
      'Choose start date': 'Selecciona la fecha de inicio',
      'Choose end date': 'Selecciona la fecha de finalización',
      'Payoff Statement Request': 'Solicitud de Balance de Cancelación',
      'We will be ready to deliver your Payoff Statement within the next 5 business days, after you submit the request.':
        'Una vez sometas la solicitud, te enviaremos tu balance de cancelación dentro de los próximos 5 días laborables.',
      'Request Details': 'Detalles de la solicitud',
      Consent: 'Consentimiento',
      'About you': 'Sobre ti',
      // Modal step one
      'Let’s get started! What is the purpose of this request?*':
        'Comencemos, ¿Cuál es el propósito de esta solicitud?*',
      'I am refinancing my property': 'Estoy refinanciando mi propiedad',
      'I am in a buying/selling process': 'Estoy en un proceso de compra/venta',
      'I will make the last payment': 'Realizaré el último pago',
      Other: 'Otro',
      'Personal purpose': 'Propósito Personal',
      'Other, specify': 'Otro, especifica',
      'Choose the specified Payoff date*:':
        'Escoge la fecha de efectividad del Balance de Cancelación*:',
      'Effective Date : *': 'Fecha de efectividad : *',
      'Effective Date': 'Fecha de efectividad',
      'Select Day in Effective Date dropdown Required':
        'Seleccione el día en la fecha de vigencia desplegable Obligatorio',
      DD: 'DD',
      'Select Month in Effective Date dropdown Required':
        'Seleccione el mes en la fecha de vigencia desplegable Obligatorio',
      MMM: 'MMM',
      'Select Year in Effective Date dropdown Required':
        'Seleccione el año en la fecha de vigencia desplegable Requerido',
      YYYY: 'AAAA',
      USA: 'EU',
      VI: 'IV',
      'Date Picker': 'Selector de fechas',
      'To prevent your Payoff Statement from expiring during the cancellation process, we recommend that you choose an effective date greater than 10 days from today.':
        'Para evitar que tu balance de cancelación expire durante el proceso de cancelación, recomendamos que escojas una fecha de efectividad mayor a 10 días a partir de hoy.',
      'More Info': 'Más información',
      'Upload your document here': 'Sube tu documento aquí',
      Jan: 'Ene',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Abr',
      May: 'May',
      Jun: 'Jun',
      Jul: 'Jul',
      Sep: 'Sep',
      Aug: 'Ago',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dic',
      // Modal step two
      'Who will receive the Payoff Statement?*': '¿Quién recibirá el balance de cancelación?*',
      'Me, I am the owner of the loan': 'Yo, soy el titular del préstamo',
      'Another person who is not the owner of the loan':
        'Otra persona que no es titular del préstamo',
      'Perfect, tell us about yourself:': 'Perfecto, cuéntanos sobre ti:',
      'Give both information':
        'Ahora, entra la información de la persona que autorizas a recibir el balance de cancelación. Al completar esta sección autorizas a que Oriental Bank comparta la información de tu Balance de Cancelacion Hipotecario con la persona identificada en este formulario.',
      Required: 'Requerido',
      'First Name': 'Nombre',
      'Middle Initial': 'Inicial',
      'Last Name': 'Apellido Paterno',
      'Second Last Name': 'Apellido Materno',
      'Mother`s Last Name': 'Apellido Materno',
      'Loan Number': 'Número de préstamo',
      'Enter loan number': 'Ingresa número de préstamo',
      Phone: 'Teléfono',
      'Mobile Number': 'Número Móvil',
      Mobile: 'Teléfono celular',
      'Alternate Phone': 'Teléfono alterno',
      Relationship: 'Relación',
      'Aria Label Relationship': 'Seleccionar persona Relación requerida',
      'My Realtor': 'Mi Realtor',
      'My Lawyer': 'Mi abogado (a)',
      'Enter license number': 'Ingresa número de licencia',
      'License Number': 'Número de licencia',
      'Area Code': 'Area Code',
      'Date of Birth': 'Fecha de Nacimiento',
      'Information of the person you authorize':
        'Ahora, entra la información de la persona que autorizas a recibir el balance de cancelación:',
      'Date Of Birth Required Select Day in Date Of Birth dropdown Required':
        'Date Of Birth Required Select Day in Date Of Birth dropdown Required',
      'Select Month in Date Of Birth dropdown Required':
        'Select Month in Date Of Birth dropdown Required',
      'Select Year in Date Of Birth dropdown Required':
        'Select Year in Date Of Birth dropdown Required',
      'Area Code Required': 'Area Code Required',
      'Aria Label Country': 'Country Required Choose your country dropdown menu button',
      'Aria Label State': 'State Required Choose your state dropdown menu button',
      'Aria Label City': 'City Required Choose your city dropdown menu button',
      'Now, let us know your email to send you the document':
        'Ahora, déjanos saber tu correo electrónico para enviarte el documento',
      'By mail': 'Por correo regular',
      'By email': 'Por correo electrónico',
      'Email Address': 'Correo electrónico',
      'Social Security Number': 'Número de Seguro Social',
      'Enter social security number': 'Ingresa número de Seguro Social',
      'Enter first name': 'Ingresa nombre',
      'Enter last name': 'Ingresa apellido',
      'Enter middle initial': 'Ingresa inicial',
      'Enter second last name': 'Ingresa apellido',
      'Enter phone number': 'Ingresa teléfono',
      'Enter email address': 'Ingresa correo electrónico',
      'Enter address details': 'Ingrese los detalles de la dirección',
      'Company Name': 'Nombre de la empresa',
      'Enter company name': 'Ingrese el nombre de la empresa',
      'Specify Relationship Type': 'Específica tipo de relación',
      'Enter relationship type': 'Ingrese el tipo de relación',
      '* Invalid Date': '* Formato Invalido',
      Back: 'Atrás',
      Continue: 'Continuar',
      Select: 'Seleccione',
      'Please select country': 'Por favor seleccione país',
      'Please select country and state': 'Seleccione el país y el estado',
      'Address Line 1': 'Dirección Línea 1',
      'Address Line 2': 'Dirección Línea 2',
      State: 'Estado',
      Country: 'País',
      City: 'Ciudad',
      'Zip Code': 'Código Postal',
      'Enter address 1': 'Ingresa dirección 1',
      'Enter address 2': 'Ingresa dirección 2',
      'Choose your country': 'Selecciona país',
      'Choose your state': 'Selecciona estado',
      'Choose your city': 'Selecciona ciudad',
      'Enter Zip Code': 'Ingresa código postal',
      Send: 'Enviar',
      Footer: '© 2021 orientalbank.com. Derechos Reservados. Miembro FDIC.',
      // Modal step two
      'Provide your consent': 'Provee tu consentimiento',
      'This application has a $10.00 cost. I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585.':
        'Esta solicitud tiene un costo de $10.00. Consiento que dicho monto sea incluido como un cargo adicional a mi préstamo hipotecario. El balance de cancelación será enviado dentro de los próximos 5 días laborables al método de envío seleccionado. De no recibir el documento en el tiempo estipulado, favor notificarlo llamando al 787.767.8585.',
      'The first payoff satement request and any update or correction to that original request will be issued at no cost to the client. Any additional payoff statement requests within a six (6) month period will be charged at $25.00.  I consent for this amount to be included as an additional charge to my mortgage loan. The Payoff Statement will be sent within the next 5 business days to the chosen delivery method. If you do not receive the document in the stipulated time, please notify it by calling 787.767.8585.':
        'La primera solicitud de balance de cancelación y cualquier actualizacion o corrección de errores se emitirá sin costo para el cliente.  Cualquier actualización adicional dentro de un período de seis (6) meses tendrá un costo de $25.00.  Consiento que dicho monto sea incluido como un cargo adicional a mi préstamo hipotecario. El balance de cancelación será enviado dentro de los próximos 5 días laborables al método de envío seleccionado. De no recibir el documento en el tiempo estipulado, favor notificarlo llamando al 787.767.8585.',
      'I consent to receive this payoff statement electronically. This consent applies solely and exclusively to the sending of this request. I acknowledge that I have the right to withdraw this electronic consent at any time and receive a paper copy of the payoff statement. To withdraw consent, request a copy of the document and change the email address, I must contact 787.767.8585 or send an email to servicio.hipotecas@orientalbank.com. We will send your payoff statement in PDF format. A PDF reader program may be required to view and print PDF files.':
        'Consiento recibir electrónicamente este balance de cancelación. Este consentimiento aplica única y exclusivamente al envío de esta solicitud.  Reconozco que tengo derecho a retirar este consentimiento electrónico en cualquier momento y recibir una copia en papel del balance de cancelación.  Para retirar el consentimiento, solicitar una copia del documento y cambiar la dirección de correo electrónico debo comunicarme al 787.767.8585 o enviar un correo electrónico a servicio.hipotecas@orientalbank.com.  Enviaremos su balance de cancelación en formato PDF. Un programa leedor de PDF puede ser requerido para ver e imprimir archivos PDF.',
      // Error Modal
      '* Mandatory Field': '* Campo requerido',
      '* Invalid format': '* Formato Invalido',
      '* Social security number should have 9 digits':
        '* El número de seguro social debe tener 9 dígitos',
      '* Areacode should have 3 digits': '* El código de área debe tener 3 dígitos',
      '* Mobile number should have 7 digits': '* El número de móvil debe tener 7 dígitos',
      '* Phone number should have 7 digits': '* El número de teléfono debe tener 7 dígitos',
      '* You must be 18 years or older': '* Debes tener 18 años o más',
      '* Invalid email': '* Formato Invalido',
      '* Date should not be greater than 30 days': '* La fecha no debe ser superior a 30 días.',
      '* Date should not be in the past': '* La fecha no debe estar en el pasado',
      '* Account number should have atleast 10 digits':
        '* El número de cuenta debe tener al menos 10 dígitos',
      // Thank you page
      'we are done': 'estamos set',
      'Done message':
        'Tu solicitud de balance de cancelación fue recibida y será enviada dentro de los próximos 5 días laborables. Tu número de referencia es CM-12345.',
      'Close and return to chat': 'Cerrar y regresar al chat',
      'Thank You Header': 'Encabezado de agradecimiento',
      'Download Payoff Form': 'Descargar solicitud de balance de cancelación',
      'Download button': 'Descargar solicitud de balance de cancelación',
      'Go to site button': 'Ir al botón orientalbank.com',
      'Go to orientalbank.com': 'Ir a orientalbank.com',
      EndLiveAgentMessage: 'Chat con Live Agent finalizado',
      // Verify Form
      'Check in as customer': 'Registrarse como cliente',
      'Form Desc':
        'Queremos proteger tu cuenta, cuéntanos más sobre ti. Esta información se enviará exclusivamente al agente en vivo y no se mostrará en el historial del chat',
      'Full Name': 'Nombre completo',
      // "Date of Birth": "Fecha de nacimiento",
      'Account number': 'Número de cuenta',
      'Last 4 social security number': 'Últimos 4 dígitos del seguro social',
      // "Send": "Enviar",
      'Continue as guest': 'Continuar como invitado',
      'Having reached required age (60 years old)': 'Alcance la edad de retiro (60 años)',
      'Personal Matters': 'Razones Personales',
      Unemployment: 'Desempleo',
      Disability: 'Incapacidad',
      'Purchase or Construction of First Home': 'Compra o Construcción de la primera residencia',
      'Purchase of a computer for the use of a dependent': 'Compra de Computadora para dependiente',
      'Death of Participant': 'Muerte del Participante',
      'Payment of debt for alimony': 'Pago de Pesión alimenticia',
      'Repair or Construction of main residence due to Fortuitous Causes':
        'Reparación o Construcción de residencia principal debido a causas fotuitas',
      'University studies of direct dependents': 'Gastos Universitarios de dependientes',
      'Treatment of severe, cronic, degenerative or terminal desease':
        'Tratamiento de condiciones severas, crónicas, degenerativas o terminales',
      'Transfer due to divorce': 'Transferencia por divorcio',
      'Foreclousure due to reduction of income':
        'Ejecución de Hipoteca debido a reducción de salario',
      'Pay for the costs of post secondary studies of the beneficiary':
        'Pagar costos de estudios post secundarios del beneficiario',
      'Reimbursement due to death or disability of the beneficiary':
        'Devolución por muerte o incapacidad del beneficiario',
      "Reimbursement of funds due to the beneficuary's decision":
        'Devolución por determinación del beneficiario',
      'Please select': 'Por favor selecciona',
      'Please Upload Documents': 'Cargar Documentos',
      'Select Files': 'Seleccionar documentos',
      'Upload a maximum of 5 documents of 10MB each':
        'Cargar hasta un máximo de 5 documentos de 10MB cada uno',
      'Uploaded file exceeds the max size': 'El documento cargado excede el tamaño máximo',
      'Remove All': 'Borrar todos los documentos',
      'Undo Removed File': 'Recuperar el documento borrado',
      'Undo Removed {num} Files': 'Recuperar los {num} documentos borrados',
      Confirm: 'Confirma',
      Cancel: 'Cancelar',
      'Please, Wait while we process your request.':
        'Por favor, espere mientras procesamos su solicitud.',
      'Supported file type': 'Tipo de archivo compatible',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'EN',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      //   format: function(value, format, lng) {
      //     if(value instanceof Date) return moment(value).format(format);
      //     return value;
      // }
    },
  });

export default i18n;
