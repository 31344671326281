import { useState } from 'react';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  SvgIcon,
  Tooltip,
  FormLabel,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import DatePickerField from './FormFields/DatePickerField';
import MoreInfoImg from '../images/More Info.png';
import { ReactComponent as DatePickerIcon } from '../images/datepicker.svg';
import { useStyles } from './OverrideStyles';
import { CommonStyles } from './CommonStyles';
import {
  AREA_LABEL_EFFECTIVE_DATE,
  AREA_LABEL_EFFECTIVE_DAY,
  BUYING_SELLING_PROCESS,
  CHOOSE_SPECIFIED_PAYOFF_DATE,
  DATE_NOT_GREATER_THAN_30_DAYS,
  DATE_PICKER,
  DATE_SHOULD_NOT_BE_IN_PAST,
  DD,
  EFFECTIVE_DATE,
  EFFECTIVE_DATE_TOOLTIP,
  INVALID_DATE_ENG,
  LETS_GET_STARTED,
  LETS_START_HEADING,
  MAKE_LAST_PAYMENT,
  MMM,
  OTHER,
  OTHER_SPECIFY,
  PERSONAL_PURPOSE,
  REFINANCING_MY_PROPERTY,
  REQUIRED,
  SELECT_MONTH_ARIA_LABEL,
  SELECT_YEAR_ARIA_LABEL,
  YYYY,
} from '../../../Constants';

const useCommonStyles = CommonStyles();
const StepOne = (props) => {
  const { t } = useTranslation();
  const formikContext = useFormikContext();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = formikContext;
  const { englishBot } = props;
  const CommonCssStyles = useCommonStyles();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [dateInvalid, setDateInvalid] = useState(false);
  const [dateInvalidInDays, setDateInvalidInDays] = useState(false);
  const [dateInPast, setDateInPast] = useState(false);
  const todayDate = new Date().setHours(0, 0, 0, 0);
  const maxSelectDate = new Date();
  maxSelectDate.setDate(maxSelectDate.getDate() + 30);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const yearGenerator = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();

    years.push(currentYear);
    if (currentMonth === 11 && currentDay > 1) {
      years.push(currentYear + 1);
    }
    return years;
  };
  const monthGenerator = () => {
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const visibleMonths = [];

    visibleMonths.push(months[currentMonth]);
    if (currentMonth !== 11 && currentDay > 1) {
      visibleMonths.push(months[currentMonth + 1]);
    } else if (currentMonth === 11 && currentDay > 1) {
      visibleMonths.push(months[0]);
    } else {
      return visibleMonths;
    }
    return visibleMonths;
  };
  const dateGenerator = () => {
    const date = [];
    for (let index = 1; index <= 31; index++) {
      if (index <= 9) {
        date.push(`0${index}`);
      } else {
        date.push(`${index}`);
      }
    }
    return date;
  };
  const handleInputChange = (e) => {
    handleChange(e);
    switch (e.target.name) {
      case 'specificReason':
        if (e.target.value) {
          formikContext.setFieldValue('otherPurpose', 'Other, specify');
        }
        setTimeout(() => {
          if (!formikContext.touched[e.target.name]) {
            formikContext.setFieldTouched([e.target.name], true);
            document.getElementById(e.target.name).focus();
          }
          if (!formikContext.touched.otherPurpose) {
            formikContext.setFieldTouched('otherPurpose', true);
          }
        }, 100);
        break;
      case 'otherPurpose':
        if (e.target.value === PERSONAL_PURPOSE && values.specificReason !== '') {
          formikContext.setFieldValue('specificReason', '');
          formikContext.setFieldTouched('specificReason', false);
        }
        break;
      default:
    }
  };
  const handleDateOfBirthChange = (input, value, formikContextValues) => {
    const tooltipDays = new Date();
    tooltipDays.setDate(tooltipDays.getDate() + 11);
    let formattedDate = null;
    let dateArray = null;
    if (input === 'effective_date') {
      formattedDate = format(value, 'dd MMM yyyy');
      dateArray = formattedDate.split(' ');
      if (isValidDate(dateArray[2], dateArray[1], dateArray[0])) {
        setDateInvalidInDays(false);
        setDateInPast(false);
        setDateInvalid(false);
        formikContextValues.setFieldValue(
          'effective_date',
          `${dateArray[0]} ${dateArray[1]} ${dateArray[2]}`
        );
        formikContextValues.setFieldValue('effective_day', dateArray[0]);
        formikContextValues.setFieldValue('effective_month', dateArray[1]);
        formikContextValues.setFieldValue('effective_year', dateArray[2]);
      }
      if (value < tooltipDays) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    }
  };
  const effectiveDateChange = (selectedDate, future) => {
    if (selectedDate > future) {
      setDateInvalidInDays(true);
    } else if (selectedDate <= todayDate) {
      setDateInPast(true);
    } else {
      setDateInvalidInDays(false);
      setDateInPast(false);
      setDateInvalid(true);
    }
  };
  const chageEffectiveDay = (e, tooltipDays, future, months) => {
    if (e.target.value !== DD && values.effective_month !== MMM && values.effective_year !== YYYY) {
      setDateInvalid(false);
      const selectedDate = new Date(
        parseInt(values.effective_year, 10),
        months.indexOf(values.effective_month),
        parseInt(e.target.value, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(values.effective_year, values.effective_month, e.target.value) &&
        selectedDate < future &&
        selectedDate >= todayDate
      ) {
        setDateInvalidInDays(false);
        setDateInPast(false);
        setFieldValue(
          'effective_date',
          `${e.target.value} ${values.effective_month} ${values.effective_year}`
        );
        setTimeout(() => {
          formikContext.setFieldTouched('effective_date', true);
        }, 100);
        if (selectedDate < tooltipDays && selectedDate >= todayDate) {
          setShowTooltip(true);
        } else {
          setShowTooltip(false);
        }
      } else {
        setFieldValue('effective_date', '');
        setTimeout(() => {
          formikContext.setFieldTouched('effective_date', false);
        }, 100);
        effectiveDateChange(selectedDate, future);
      }
    } else {
      setFieldValue('effective_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('effective_date', false);
      }, 100);
      setDateInvalidInDays(false);
      setDateInPast(false);
      setDateInvalid(false);
    }
  };

  const changeEffectiveMonth = (e, tooltipDays, future, months) => {
    if (values.effective_day !== DD && e.target.value !== MMM && values.effective_year !== YYYY) {
      setDateInvalid(false);
      const selectedDate = new Date(
        parseInt(values.effective_year, 10),
        months.indexOf(e.target.value),
        parseInt(values.effective_day, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(values.effective_year, e.target.value, values.effective_day) &&
        selectedDate < future &&
        selectedDate >= todayDate
      ) {
        setDateInvalidInDays(false);
        setDateInPast(false);
        setFieldValue(
          'effective_date',
          `${values.effective_day} ${e.target.value} ${values.effective_year}`
        );
        setTimeout(() => {
          formikContext.setFieldTouched('effective_date', true);
        }, 100);
        effectiveYearChange(selectedDate, tooltipDays);
      } else {
        setShowTooltip(false);
        setFieldValue('effective_date', '');
        setTimeout(() => {
          formikContext.setFieldTouched('effective_date', false);
        }, 100);
        if (selectedDate > future) {
          setDateInvalidInDays(true);
        } else if (selectedDate <= todayDate) {
          setDateInPast(true);
        } else {
          setDateInvalidInDays(false);
          setDateInPast(false);
          setDateInvalid(true);
        }
      }
    } else {
      setShowTooltip(false);
      setShowTooltip(false);
      setFieldValue('effective_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('effective_date', false);
      }, 100);
      setDateInvalidInDays(false);
      setDateInPast(false);
      setDateInvalid(false);
    }
  };
  const effectiveYearChange = (selectedDate, tooltipDays) => {
    if (selectedDate < tooltipDays && selectedDate >= todayDate) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const changeEffectiveYear = (e, tooltipDays, future, months) => {
    if (values.effective_day !== DD && values.effective_month !== MMM && e.target.value !== YYYY) {
      setDateInvalid(false);
      const selectedDate = new Date(
        parseInt(e.target.value, 10),
        months.indexOf(values.effective_month),
        parseInt(values.effective_day, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(e.target.value, values.effective_month, values.effective_day) &&
        selectedDate < future &&
        selectedDate >= todayDate
      ) {
        setDateInvalidInDays(false);
        setDateInPast(false);
        setFieldValue(
          'effective_date',
          `${values.effective_day} ${values.effective_month} ${e.target.value}`
        );
        setTimeout(() => {
          formikContext.setFieldTouched('effective_date', true);
        }, 100);
        effectiveYearChange(selectedDate, tooltipDays);
      } else {
        setShowTooltip(false);
        setFieldValue('effective_date', '');
        setTimeout(() => {
          formikContext.setFieldTouched('effective_date', false);
        }, 100);
        if (selectedDate > future) {
          setDateInvalidInDays(true);
        } else if (selectedDate <= todayDate) {
          setDateInPast(true);
        } else {
          setDateInvalidInDays(false);
          setDateInPast(false);
          setDateInvalid(true);
        }
      }
    } else {
      setShowTooltip(false);
      setFieldValue('effective_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('effective_date', false);
      }, 100);
      setDateInvalidInDays(false);
      setDateInPast(false);
      setDateInvalid(false);
    }
  };
  const handleCondition = (e) => {
    const tooltipDays = new Date();
    tooltipDays.setDate(tooltipDays.getDate() + 11);
    const future = new Date();
    future.setDate(future.getDate() + 31);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    switch (e.target.name) {
      case 'effective_day':
        chageEffectiveDay(e, tooltipDays, future, months);
        break;
      case 'effective_month':
        changeEffectiveMonth();
        break;
      case 'effective_year':
        changeEffectiveYear(e, tooltipDays, future, months);
        break;
      default:
    }
  };
  const handleDateOfBirthChangeDropdown = (e) => {
    handleChange(e);
    setTimeout(() => {
      if (!formikContext.touched[e.target.name]) {
        formikContext.setFieldTouched([e.target.name], true);
        document.getElementById(e.target.name).focus();
      }
    }, 100);
    handleCondition(e);
  };
  const isValidDate = (year, month, day) => {
    const result = moment(`${day}/${month}/${year}`, 'DD/MMM/YYYY', true).isValid();
    return !!result;
  };
  return (
    <Grid item xs={12} md={8}>
      <Grid
        container
        item
        xs={12}
        md={12}
        style={{ margin: '10px 0' }}
        role="region"
        aria-labelledby={LETS_START_HEADING}
      >
        <Grid item xs={12} md={12} style={{ margin: '12px 0' }}>
          <FormLabel className={classes.main_label} id="letsStartheading">
            {t(LETS_GET_STARTED, {
              defaultValue: LETS_GET_STARTED,
              nsSeparator: '|',
            })}
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <RadioGroup
            aria-label="purpose"
            id="purpose"
            name="purpose"
            data-testid="puropse_testid"
            value={values.purpose}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            <FormControlLabel
              className={classes.radio_label}
              value={REFINANCING_MY_PROPERTY}
              control={<Radio />}
              label={t(REFINANCING_MY_PROPERTY, {
                defaultValue: REFINANCING_MY_PROPERTY,
              })}
            />
            <FormControlLabel
              className={classes.radio_label}
              value={BUYING_SELLING_PROCESS}
              control={<Radio />}
              label={t(BUYING_SELLING_PROCESS, {
                defaultValue: BUYING_SELLING_PROCESS,
                nsSeparator: '|',
              })}
            />
            <FormControlLabel
              className={classes.radio_label}
              value={MAKE_LAST_PAYMENT}
              control={<Radio />}
              label={t(MAKE_LAST_PAYMENT, {
                defaultValue: MAKE_LAST_PAYMENT,
              })}
            />
            <FormControlLabel
              className={classes.radio_label}
              value={OTHER}
              control={<Radio />}
              label={t(OTHER, { defaultValue: OTHER })}
            />
            {values.purpose === OTHER && (
              <Grid item xs={12} style={{ marginLeft: '23px' }}>
                <RadioGroup
                  aria-label="other purpose"
                  name="otherPurpose"
                  value={values.otherPurpose}
                  data-testid="radiGroup_testid"
                  onBlur={handleBlur}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    style={{ display: 'block' }}
                    className={classes.radio_label}
                    value="Personal purpose"
                    control={<Radio />}
                    label={t(PERSONAL_PURPOSE, { defaultValue: PERSONAL_PURPOSE })}
                  />
                  <Grid item xs={12}>
                    <FormControlLabel
                      style={{ display: 'inline-block' }}
                      className={classes.radio_label}
                      value="Other, specify"
                      control={<Radio />}
                      label={t(OTHER_SPECIFY, { defaultValue: OTHER_SPECIFY })}
                    />
                    :
                    <TextField
                      className={classes.radio_input}
                      inputProps={{ maxLength: 50, 'data-testid': 'specificReason_testid' }}
                      style={{ display: 'inline-block', marginLeft: '5px' }}
                      value={values.specificReason}
                      onBlur={handleBlur}
                      onChange={handleInputChange}
                      id="specificReason"
                      name="specificReason"
                    />
                  </Grid>
                </RadioGroup>
              </Grid>
            )}
          </RadioGroup>
          <span className={CommonCssStyles.error_message_input} style={{ textAlign: 'left' }}>
            {errors.purpose && touched.purpose && (
              <span role="alert" tabIndex="-1">
                {t(errors.purpose, { defaultValue: errors.purpose })}
              </span>
            )}
            {errors.otherPurpose && touched.otherPurpose && (
              <span role="alert" tabIndex="-1">
                {t(errors.otherPurpose, { defaultValue: errors.otherPurpose })}
              </span>
            )}
            {errors.specificReason && touched.specificReason && (
              <span role="alert" tabIndex="-1">
                {t(errors.specificReason, { defaultValue: errors.specificReason })}
              </span>
            )}
          </span>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={12}
        className={`${CommonCssStyles.inputs_grid_margin} dob-input-adjust`}
      >
        <Grid item xs={12} style={{ margin: '12px 0' }}>
          <FormLabel className={classes.main_label}>
            {t(CHOOSE_SPECIFIED_PAYOFF_DATE, {
              defaultValue: CHOOSE_SPECIFIED_PAYOFF_DATE,
              nsSeparator: '|',
            })}
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={7} sm={8} style={{ paddingLeft: '7px' }}>
          <InputLabel
            htmlFor="effective_day"
            required
            aria-label={`${t(AREA_LABEL_EFFECTIVE_DATE, {
              defaultValue: AREA_LABEL_EFFECTIVE_DATE,
              nsSeparator: '|',
            })} ${t(REQUIRED, { defaultValue: REQUIRED })}`}
            className={CommonCssStyles.label_title}
          >
            {t(EFFECTIVE_DATE, { defaultValue: EFFECTIVE_DATE })}:
          </InputLabel>
          <Grid container>
            <Grid item xs={3} sm={2} md={2} lg={3} className="dob-select-day">
              <Select
                id="effective_day"
                name="effective_day"
                variant="outlined"
                data-testid="slectBirthday_testid"
                role="listbox"
                fullWidth
                aria-label={`${t(CHOOSE_SPECIFIED_PAYOFF_DATE, {
                  defaultValue: CHOOSE_SPECIFIED_PAYOFF_DATE,
                  nsSeparator: '|',
                })} ${t(EFFECTIVE_DATE, { defaultValue: EFFECTIVE_DATE })}: ${t(REQUIRED, {
                  defaultValue: REQUIRED,
                })} ${t(AREA_LABEL_EFFECTIVE_DAY, {
                  defaultValue: AREA_LABEL_EFFECTIVE_DAY,
                })}`}
                className={`${CommonCssStyles.date_of_birth_select} ${CommonCssStyles.dob_day_select} ${CommonCssStyles.input_disabled_style} `}
                value={values.effective_day}
                classes={{
                  icon: CommonCssStyles.select_icon,
                }}
                onBlur={handleBlur}
                onChange={handleDateOfBirthChangeDropdown}
              >
                <MenuItem value="DD" role="option">
                  {t(DD, { defaultValue: DD })}
                </MenuItem>
                {dateGenerator().length !== 0
                  ? dateGenerator().map((date) => (
                      <MenuItem key={date} role="option" value={date}>
                        {date}
                      </MenuItem>
                    ))
                  : ''}
              </Select>
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={3} className="dob-select">
              <Select
                id="effective_month"
                name="effective_month"
                variant="outlined"
                fullWidth
                role="listbox"
                aria-label={`${t(SELECT_MONTH_ARIA_LABEL, {
                  defaultValue: SELECT_MONTH_ARIA_LABEL,
                })}`}
                className={`${CommonCssStyles.date_of_birth_select} ${CommonCssStyles.dob_month_year_select} ${CommonCssStyles.input_disabled_style}`}
                onBlur={handleBlur}
                onChange={handleDateOfBirthChangeDropdown}
                value={values.effective_month}
                classes={{
                  icon: CommonCssStyles.select_icon,
                }}
              >
                <MenuItem value="MMM" role="option">
                  {t(MMM, { defaultValue: MMM })}
                </MenuItem>
                {monthGenerator().length !== 0
                  ? monthGenerator().map((month) => (
                      <MenuItem key={month} role="option" value={month}>
                        {t(month, { defaultValue: month })}
                      </MenuItem>
                    ))
                  : ''}
              </Select>
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={2} className="dob-select">
              <Select
                id="effective_year"
                name="effective_year"
                variant="outlined"
                role="listbox"
                aria-label={`${t(SELECT_YEAR_ARIA_LABEL, {
                  defaultValue: SELECT_YEAR_ARIA_LABEL,
                })}`}
                fullWidth
                className={`${CommonCssStyles.date_of_birth_select} ${CommonCssStyles.dob_month_year_select} ${CommonCssStyles.input_disabled_style} `}
                onBlur={handleBlur}
                onChange={handleDateOfBirthChangeDropdown}
                value={values.effective_year}
                classes={{
                  icon: CommonCssStyles.select_icon,
                }}
              >
                <MenuItem value="YYYY" role="option">
                  {t(YYYY, { defaultValue: YYYY })}
                </MenuItem>
                {yearGenerator().length !== 0
                  ? yearGenerator().map((year) => (
                      <MenuItem key={year} role="option" value={year}>
                        {year}
                      </MenuItem>
                    ))
                  : ''}
              </Select>
            </Grid>
            <Grid
              container
              item
              xs={2}
              sm={2}
              md={2}
              lg={3}
              className="datePickerbtn dob-select-datepicker"
            >
              <Grid item xs={12} sm={12} style={{ textAlign: 'end' }}>
                <SvgIcon
                  aria-label={t(DATE_PICKER, { defaultValue: DATE_PICKER })}
                  tabIndex="0"
                  role="graphic"
                  data-testid="openDialog_testid"
                  titleAccess={t(DATE_PICKER, { defaultValue: DATE_PICKER })}
                  component={DatePickerIcon}
                  viewBox="0 0 29 29"
                  onClick={handleClickOpen}
                  style={{ cursor: 'pointer', marginLeft: '1px' }}
                />
              </Grid>
              <DatePickerField
                language={englishBot}
                handleDateOfBirthChange={handleDateOfBirthChange}
                date={values.effective_date}
                open={open}
                onClose={handleClose}
                minDateValue={todayDate}
                maxDateValue={maxSelectDate}
                field="effective_date"
              />
            </Grid>
            {showTooltip && (
              <Tooltip
                title={t(EFFECTIVE_DATE_TOOLTIP, {
                  defaultValue: EFFECTIVE_DATE_TOOLTIP,
                })}
                arrow
                placement={isMobile ? 'top' : 'right'}
                leaveTouchDelay={10000}
                enterTouchDelay={50}
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.arrow,
                }}
              >
                <img
                  src={MoreInfoImg}
                  tabIndex="0"
                  alt="More Info"
                  aria-label="More Info"
                  style={{
                    cursor: 'pointer',
                    marginLeft: '1px',
                    width: '17px',
                    height: '17px',
                    position: 'relative',
                    right: '-15px',
                    top: '10px',
                  }}
                />
              </Tooltip>
            )}
          </Grid>
          <span className={CommonCssStyles.error_message_input} style={{ textAlign: 'left' }}>
            {errors.effective_date &&
              touched.effective_date &&
              !dateInvalid &&
              !dateInvalidInDays &&
              !dateInPast && (
                <span role="alert" tabIndex="-1">
                  {t(errors.effective_date, { defaultValue: errors.effective_date })}
                </span>
              )}
            {dateInvalid && !dateInPast && !dateInvalidInDays && (
              <span role="alert" tabIndex="-1">
                {t(INVALID_DATE_ENG, { defaultValue: INVALID_DATE_ENG, nsSeparator: '|' })}
              </span>
            )}
            {dateInvalidInDays && !dateInvalid && !dateInPast && (
              <span role="alert" tabIndex="-1">
                {t(DATE_NOT_GREATER_THAN_30_DAYS, {
                  defaultValue: DATE_NOT_GREATER_THAN_30_DAYS,
                  nsSeparator: '|',
                })}
              </span>
            )}
            {dateInPast && !dateInvalidInDays && !dateInvalid && (
              <span role="alert" tabIndex="-1">
                {t(DATE_SHOULD_NOT_BE_IN_PAST, {
                  defaultValue: DATE_SHOULD_NOT_BE_IN_PAST,
                  nsSeparator: '|',
                })}
              </span>
            )}
          </span>
        </Grid>
      </Grid>
    </Grid>
  );
};
StepOne.propTypes = {
  englishBot: PropTypes.bool,
};
export default StepOne;
