import { PropTypes } from 'prop-types';

const Dropdown = ({ countriesList }) => (
  <select className="custom-select">
    {countriesList.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ))}
  </select>
);
Dropdown.propTypes = {
  countriesList: PropTypes.array,
};
export default Dropdown;
