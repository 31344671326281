import React from 'react';
import { Grid, InputLabel, OutlinedInput, Typography, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { IconButton } from '@livechat/ui-kit';
import { isMobile, isTablet, mobileVendor } from 'react-device-detect';
import SelectInput from '../balance-cancellation/FormFields/SelectInput';
import { CommonStyles } from '../balance-cancellation/CommonStyles';
import { VerifyFormCss } from './VerifyFormCss';
import { formatPhoneNumber } from '../utils/CommonFunctions';
import { PropTypes } from 'prop-types';
import {
  ALT_MOBILE_NUMBER,
  ALT_MOBILE_NUM_AREA_CODE,
  BACK_ENG,
  BACK_ESP,
  CONSENT_FOR_CALLS_ENG,
  CONSENT_FOR_CALLS_ESP,
  EMAIL_ADDRESS,
  ENTER_EMAIL_ADDRESS,
  ENTER_FIRST_NAME,
  ENTER_LAST_NAME,
  ENTER_MOBILE_NUMBER,
  FIRST_NAME,
  LAST_NAME,
  LET_US_KNOW_EMAIL,
  LOOKING_INFO_ABOUT_ENG,
  LOOKING_INFO_ABOUT_ESP,
  MOBILE,
  MOBILE_NUMBER,
  MOBILE_NUMBER_CAPS,
  NEED_CONTACT_INFO_ENG,
  NEED_CONTACT_INFO_ESP,
  OTHER_ALT_MOBILE_NUMBER,
  OTHER_MOBILE_NUMBER,
  REQUIRED,
  SEND_ENG,
  SEND_ESP,
  TELL_US_ABOUT_YOURSELF,
} from '../../../Constants';
const useVerifyFormCss = VerifyFormCss();
const useCommonStyles = CommonStyles();
const commonCheck2 = (englishBot, eng, esp) => {
  if (englishBot) return eng;
  else return esp;
};
const checkName = (errors, touched, values, value) => {
  if ((errors && touched) || (errors && values !== '')) return value;
  else return {};
};
const checkMobileNum = (errors, touched, values, value) => {
  if (
    (errors.mobileNumber && touched.mobileNumber) ||
    (errors.mobileNumberAreacode && touched.mobileNumberAreacode) ||
    (errors.mobileNumber && values.mobileNumber !== '') ||
    (errors.mobileNumberAreacode && values.mobileNumberAreacode !== '')
  ) {
    return value;
  } else return {};
};
const checkCondition = (errors, touched, values) => {
  return (
    (errors.mobileNumber && touched.mobileNumber) ||
    (errors.mobileNumberAreacode && touched.mobileNumberAreacode) ||
    (errors.mobileNumber && values.mobileNumber !== '') ||
    (errors.mobileNumberAreacode && values.mobileNumberAreacode !== '')
  );
};
const mobileMaxLen = (isMobile1, mobileVendor1) => {
  if (isMobile1 && mobileVendor1 === 'Apple') return 8;
  else return 7;
};
const mobileInpMode = (isMobile1, isTablet1) => {
  if (isMobile1 || isTablet1) return 'numeric';
  else return 'text';
};

const GeneralForm = (props) => {
  const CommonCssStyles = useCommonStyles();
  const VerifyFormCssStyles = useVerifyFormCss();
  const { englishBot, setGeneralFormTrigger, setServiceFormTrigger, spinnerHandler } = props;
  const { t } = useTranslation();
  const formikContext = useFormikContext();
  const { values, errors, touched, handleChange, handleBlur, isSubmitting } = formikContext;
  const menuLabel = commonCheck2(englishBot, LOOKING_INFO_ABOUT_ENG, LOOKING_INFO_ABOUT_ESP);
  const menuObject = [
    { labelEng: 'Mortgage', labelEsp: 'Hipoteca', value: 'mortgage' },
    { labelEng: 'Other Accounts', labelEsp: 'Otras cuentas', value: 'other account' },
  ];
  const inputHandleChange = (e) => {
    handleChange(e);
    setTimeout(() => {
      if (!formikContext.touched[e.target.name]) {
        formikContext.setFieldTouched([e.target.name], true);
        if (document.getElementById(e.target.name)) {
          document.getElementById(e.target.name).focus();
        }
      }
    }, 100);
    if (
      e.target.name === OTHER_MOBILE_NUMBER ||
      e.target.name === OTHER_ALT_MOBILE_NUMBER ||
      e.target.name === MOBILE_NUMBER ||
      e.target.name === ALT_MOBILE_NUMBER
    ) {
      if (e.target.value.match(/^\d{7}$/)) {
        formikContext.setFieldValue(e.target.name, formatPhoneNumber(e.target.value));
      } else {
        const value = e.target.value.replace(/-/g, '');
        formikContext.setFieldValue(e.target.name, value);
      }
      setTimeout(() => {
        formikContext.setFieldTouched([e.target.name], true);
      }, 100);
    }
    if (
      e.target.name === ALT_MOBILE_NUM_AREA_CODE &&
      e.target.value === '' &&
      values.altMobileNumber !== ''
    ) {
      formikContext.setFieldTouched([e.target.name], true);
    }
  };
  const backToServiceHandler = () => {
    spinnerHandler(false);
    setServiceFormTrigger(false);
    setGeneralFormTrigger(false);
  };
  const outlined_inputs_error = {
    background: '#FD00000D',
  };
  const sendOpacity = {
    opacity: '0.5',
    pointerEvents: 'none',
  };
  const sendOpacityFull = {
    opacity: '1',
  };
  return (
    <div id="customerCheckIn" className={CommonCssStyles.gen_form_contain}>
      <Grid item xs={12} className={VerifyFormCssStyles.form_heading_wrapper}>
        <Typography
          variant="div"
          id="dialog1Title"
          className={VerifyFormCssStyles.form_heading_customerCheckIn}
        >
          <div className={CommonCssStyles.service_acc_margin}>
            <span className={CommonCssStyles.service_acc_font}>
              {commonCheck2(englishBot, NEED_CONTACT_INFO_ENG, NEED_CONTACT_INFO_ESP)}
            </span>
            {commonCheck2(englishBot, CONSENT_FOR_CALLS_ENG, CONSENT_FOR_CALLS_ESP)}
          </div>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={checkName(
          errors.firstName,
          touched.firstName,
          values.firstName,
          CommonCssStyles.ErrorEnableGrid
        )}
      >
        <InputLabel
          htmlFor="firstName"
          className={CommonCssStyles.label_title}
          aria-label={`${t(TELL_US_ABOUT_YOURSELF, {
            defaultValue: TELL_US_ABOUT_YOURSELF,
            nsSeparator: '|',
          })} ${t(FIRST_NAME, { defaultValue: FIRST_NAME })} ${t(REQUIRED, {
            defaultValue: REQUIRED,
          })}`}
          required
        >
          {t(FIRST_NAME, { defaultValue: FIRST_NAME })}:
        </InputLabel>
        <OutlinedInput
          name="firstName"
          id="firstName"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
          placeholder={t(ENTER_FIRST_NAME, { defaultValue: ENTER_FIRST_NAME })}
          fullWidth
          inputProps={{ maxLength: 10, 'data-testid': 'firstName_testid' }}
          value={values.firstName}
          onChange={inputHandleChange}
          onBlur={handleBlur}
          autoComplete="off"
          style={checkName(
            errors.firstName,
            touched.firstName,
            values.firstName,
            outlined_inputs_error
          )}
        ></OutlinedInput>
        <span className={CommonCssStyles.error_message_input}>
          {((errors.firstName && touched.firstName) ||
            (errors.firstName && values.firstName !== '')) && (
            <span role="alert" tabIndex="-1">
              {t(errors.firstName, { defaultValue: errors.firstName })}
            </span>
          )}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        className={checkName(
          errors.lastName,
          touched.lastName,
          values.lastName,
          CommonCssStyles.ErrorEnableGrid
        )}
      >
        <InputLabel
          required
          htmlFor="lastName"
          aria-label={`${t(LAST_NAME, { defaultValue: LAST_NAME })} ${t(REQUIRED, {
            defaultValue: REQUIRED,
          })}`}
          className={CommonCssStyles.label_title}
        >
          {t(LAST_NAME, { defaultValue: LAST_NAME })}:
        </InputLabel>
        <OutlinedInput
          name="lastName"
          id="lastName"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
          placeholder={t(ENTER_LAST_NAME, { defaultValue: ENTER_LAST_NAME })}
          inputProps={{ maxLength: 15, 'data-testid': 'lastName_testid' }}
          value={values.lastName}
          fullWidth
          onBlur={handleBlur}
          onChange={inputHandleChange}
          autoComplete="off"
          style={checkName(
            errors.lastName,
            touched.lastName,
            values.lastName,
            outlined_inputs_error
          )}
        ></OutlinedInput>
        <span className={CommonCssStyles.error_message_input}>
          {((errors.lastName && touched.lastName) ||
            (errors.lastName && values.lastName !== '')) && (
            <span role="alert" tabIndex="-1">
              {t(errors.lastName, { defaultValue: errors.lastName })}
            </span>
          )}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        className={checkName(
          errors.personEmail,
          touched.personEmail,
          values.personEmail,
          CommonCssStyles.ErrorEnableGrid
        )}
      >
        <InputLabel
          required
          htmlFor="personEmail"
          aria-label={`${t(LET_US_KNOW_EMAIL, {
            defaultValue: LET_US_KNOW_EMAIL,
          })}: ${t(EMAIL_ADDRESS, { defaultValue: EMAIL_ADDRESS })} ${t(REQUIRED, {
            defaultValue: REQUIRED,
          })}`}
          className={CommonCssStyles.label_title}
        >
          {t(EMAIL_ADDRESS, { defaultValue: EMAIL_ADDRESS })}:
        </InputLabel>
        <OutlinedInput
          name="personEmail"
          id="personEmail"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
          placeholder={t(ENTER_EMAIL_ADDRESS, { defaultValue: ENTER_EMAIL_ADDRESS })}
          fullWidth
          inputProps={{
            inputMode: isMobile || isTablet ? 'email' : 'text',
            'data-testid': 'personEmail_testid',
          }}
          value={values.personEmail}
          onChange={inputHandleChange}
          onBlur={handleBlur}
          autoComplete="off"
          style={checkName(
            errors.personEmail,
            touched.personEmail,
            values.personEmail,
            outlined_inputs_error
          )}
        ></OutlinedInput>
        <span className={CommonCssStyles.error_message_input}>
          {((errors.personEmail && touched.personEmail) ||
            (errors.personEmail && values.personEmail !== '')) && (
            <span tabIndex="-1" role="alert">
              {t(errors.personEmail, { defaultValue: errors.personEmail })}
            </span>
          )}
        </span>
      </Grid>
      <Grid
        item
        md={12}
        className={checkMobileNum(errors, touched, values, CommonCssStyles.ErrorEnableGrid)}
      >
        <InputLabel
          required
          htmlFor="mobileNumber"
          aria-label={`${t(MOBILE_NUMBER_CAPS, { defaultValue: MOBILE_NUMBER_CAPS })} ${t(
            REQUIRED,
            { defaultValue: REQUIRED }
          )}`}
          className={CommonCssStyles.label_title}
        >
          {t(MOBILE, { defaultValue: MOBILE })}:
        </InputLabel>

        <OutlinedInput
          name="mobileNumber"
          id="mobileNumber"
          className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.mobileFormatStyle}`}
          placeholder={t(ENTER_MOBILE_NUMBER, { defaultValue: ENTER_MOBILE_NUMBER })}
          fullWidth
          inputProps={{
            maxLength: mobileMaxLen(isMobile, mobileVendor),
            inputMode: mobileInpMode(isMobile, isTablet),
            'data-testid': 'mobileNumber_testid',
          }}
          autoComplete="off"
          value={values.mobileNumber}
          onBlur={handleBlur}
          onChange={inputHandleChange}
          style={checkMobileNum(errors, touched, values, outlined_inputs_error)}
          startAdornment={
            <InputAdornment
              position="start"
              className="personal_mo_number"
              style={{ marginRight: '0', minWidth: '70px' }}
            >
              <OutlinedInput
                inputProps={{
                  maxLength: 3,
                  'data-testid': 'mobileNumberAreacode_testid',
                  inputMode: mobileInpMode(isMobile, isTablet),
                  'aria-label': `${t('Area Code', { defaultValue: 'Area Code' })} ${t('Required', {
                    defaultValue: 'Required',
                  })}`,
                }}
                id="mobileNumberAreacode"
                style={{ width: '70px' }}
                autoComplete="off"
                name="mobileNumberAreacode"
                value={values.mobileNumberAreacode}
                onBlur={handleBlur}
                onChange={inputHandleChange}
                className={CommonCssStyles.area_code_input}
              ></OutlinedInput>
            </InputAdornment>
          }
        ></OutlinedInput>
        <span className={CommonCssStyles.error_message_input}>
          {checkCondition(errors, touched, values) && (
            <span role="alert" tabIndex="-1">
              {t(errors.mobileNumberAreacode, {
                defaultValue: errors.mobileNumberAreacode,
              })}
              {t(errors.mobileNumber, {
                defaultValue: errors.mobileNumber,
              })}
            </span>
          )}
        </span>
      </Grid>
      <Grid item xs={12} md={12}>
        <SelectInput
          englishBot={englishBot}
          menuObject={menuObject}
          menuLabel={menuLabel}
          inputHandleChange={inputHandleChange}
        />
      </Grid>
      <div
        className="form-btn"
        style={{
          width: '100%',
          marginTop: '10px',
        }}
      >
        <IconButton
          disabled={isSubmitting}
          className="end-btn"
          role="button"
          style={
            Object.keys(errors).length === 0 && Object.keys(touched).length > 0
              ? sendOpacityFull
              : sendOpacity
          }
          data-testid="send_testid"
        >
          {commonCheck2(englishBot, SEND_ENG, SEND_ESP)}
        </IconButton>
        <IconButton
          className="end-btn"
          onClick={backToServiceHandler}
          data-testid="backToServiceHandler_testid"
        >
          {commonCheck2(englishBot, BACK_ENG, BACK_ESP)}
        </IconButton>
      </div>
    </div>
  );
};

GeneralForm.propTypes = {
  englishBot: PropTypes.bool,
  setServiceFormTrigger: PropTypes.func,
  setGeneralFormTrigger: PropTypes.func,
  spinnerHandler: PropTypes.func,
};

export default GeneralForm;
