import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PropTypes } from 'prop-types';
import '../../../index.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  PopoverClasses: { 'select-popover': 'select-popover' },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

export default function DropDownWidget(props) {
  const { englishBot, values, handleMessage } = props;
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState('none');
  const defaultOption = englishBot ? 'Please select' : 'Por favor selecciona';

  const handleSelect = (event) => {
    setSelectedValue(event.target.value);
    handleMessage({ text: event.target.value, value: event.target.value }, true);
  };

  return (
    <div>
      <FormControl
        variant="outlined"
        fullWidth
        className={`select-helper-wrapper ${classes.formControl}`}
      >
        <Select
          id="simple-select-helper"
          name="simple-select-helper"
          inputProps={{ 'data-testid': 'simple-select-helper' }}
          value={selectedValue}
          defaultValue="none"
          onChange={handleSelect}
          MenuProps={MenuProps}
          renderValue={(opt) => (opt == 'none' ? defaultOption : opt)}
        >
          {values.map((item) => (
            <MenuItem key={item} value={item} className="select-menu-item">
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

DropDownWidget.propTypes = {
  englishBot: PropTypes.bool,
  values: PropTypes.array,
  handleMessage: PropTypes.func,
};
