// eslint-disable sonarjs/cognitive-complexity
import { useState } from 'react';
import { Grid, InputLabel, OutlinedInput, FormLabel, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { isMobile, isTablet, mobileVendor } from 'react-device-detect';
import { PropTypes } from 'prop-types';
import { format } from 'date-fns';
import { CommonStyles } from './CommonStyles';
import { useStyles } from './OverrideStyles';
import SSNMask from './FormFields/SSNMask';
import DateInput from './FormFields/DateInput';
import EmailInput from './FormFields/EmailInput';
import { isValidDate, formatPhoneNumber } from '../utils/CommonFunctions';
import {
  ALTERNATE_PHONE,
  ALT_MOBILE_NUMBER,
  ALT_MOBILE_NUM_AREA_CODE,
  AREA_CODE,
  DD,
  ENTER_FIRST_NAME,
  ENTER_LAST_NAME,
  ENTER_LOAN_NUMBER,
  ENTER_MIDDLE_INITINAL,
  ENTER_MOBILE_NUMBER,
  ENTER_SECOND_LAST_NAME,
  FIRST_NAME,
  LAST_NAME,
  LET_US_KNOW_EMAIL,
  LOAN_NUMBER,
  MIDDLE_INITINAL,
  MMM,
  MOBILE,
  MOBILE_NUMBER,
  MOBILE_NUMBER_CAPS,
  MOTHERS_LAST_NAME,
  OTHER_ALT_MOBILE_NUMBER,
  OTHER_MOBILE_NUMBER,
  REQUIRED,
  TELL_US_ABOUT_YOURSELF,
  YYYY,
} from '../../../Constants';

const useCommonStyles = CommonStyles();
function StepTwo(props) {
  const classes = useStyles();
  const CommonCssStyles = useCommonStyles();
  const { englishBot, stepBackFlag } = props;
  const todayDate = new Date();
  const formikContext = useFormikContext();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = formikContext;
  const { t } = useTranslation();
  const [dateInvalid, setDateInvalid] = useState(false);
  const [DOBInvalid, setDOBInvalid] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDateOfBirthChange = (input, value, formikContextValues) => {
    let formattedDate = null;
    let dateArray = null;
    if (input === 'dob_date') {
      formattedDate = format(value, 'dd MMM yyyy');
      dateArray = formattedDate.split(' ');
      if (isValidDate(dateArray[2], dateArray[1], dateArray[0])) {
        setDateInvalid(false);
        setDOBInvalid(false);
        formikContextValues.setFieldValue(
          'dob_date',
          `${dateArray[0]} ${dateArray[1]} ${dateArray[2]}`
        );
        formikContextValues.setFieldValue('dob_day', dateArray[0]);
        formikContextValues.setFieldValue('dob_month', dateArray[1]);
        formikContextValues.setFieldValue('dob_year', dateArray[2]);
        if (value < todayDate) {
          setDOBInvalid(true);
        } else {
          setDOBInvalid(false);
        }
      }
    }
  };
  const changeDobDay = (e, months) => {
    if (e.target.value !== DD && values.dob_month !== MMM && values.dob_year !== YYYY) {
      setDateInvalid(false);
      setDOBInvalid(false);
      const selectedDate = new Date(
        parseInt(values.dob_year, 10) + 18,
        months.indexOf(values.dob_month),
        parseInt(e.target.value, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(values.dob_year, values.dob_month, e.target.value) &&
        selectedDate < todayDate
      ) {
        setFieldValue('dob_date', `${e.target.value} ${values.dob_month} ${values.dob_year}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
      } else {
        setFieldValue('dob_date', '');
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', false);
        }, 100);
        if (selectedDate < todayDate) {
          setDOBInvalid(false);

          setDateInvalid(true);
        } else {
          setDOBInvalid(true);
          setDateInvalid(false);
        }
      }
    } else {
      setFieldValue('dob_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('dob_date', false);
      }, 100);
      setDateInvalid(false);
      setDOBInvalid(false);
    }
  };
  const changeDobMonth = (e, months) => {
    if (values.dob_day !== DD && e.target.value !== MMM && values.dob_year !== YYYY) {
      setDOBInvalid(false);
      setDateInvalid(false);

      const selectedDate = new Date(
        parseInt(values.dob_year, 10) + 18,
        months.indexOf(e.target.value),
        parseInt(values.dob_day, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(values.dob_year, e.target.value, values.dob_day) &&
        selectedDate < todayDate
      ) {
        setFieldValue('dob_date', `${values.dob_day} ${e.target.value} ${values.dob_year}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
      } else {
        setFieldValue('dob_date', '');
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', false);
        }, 100);
        if (selectedDate < todayDate) {
          setDateInvalid(true);
          setDOBInvalid(false);
        } else {
          setDOBInvalid(true);
          setDateInvalid(false);
        }
      }
    } else {
      setFieldValue('dob_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('dob_date', false);
      }, 100);
      setDateInvalid(false);
      setDOBInvalid(false);
    }
  };
  const changeDobYear = (e, months) => {
    if (values.dob_day !== DD && values.dob_month !== MMM && e.target.value !== YYYY) {
      setDOBInvalid(false);

      setDateInvalid(false);
      const selectedDate = new Date(
        parseInt(e.target.value, 10) + 18,
        months.indexOf(values.dob_month),
        parseInt(values.dob_day, 10)
      ).setHours(0, 0, 0, 0);
      if (
        isValidDate(e.target.value, values.dob_month, values.dob_day) &&
        selectedDate < todayDate
      ) {
        setFieldValue('dob_date', `${values.dob_day} ${values.dob_month} ${e.target.value}`);
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', true);
        }, 100);
      } else {
        setFieldValue('dob_date', '');
        setTimeout(() => {
          formikContext.setFieldTouched('dob_date', false);
        }, 100);
        if (selectedDate < todayDate) {
          setDateInvalid(true);
          setDOBInvalid(false);
        } else {
          setDOBInvalid(true);
          setDateInvalid(false);
        }
      }
    } else {
      setFieldValue('dob_date', '');
      setTimeout(() => {
        formikContext.setFieldTouched('dob_date', false);
      }, 100);
      setDateInvalid(false);
      setDOBInvalid(false);
    }
  };
  const handleDateOfBirthChangeDropdown = (e) => {
    handleChange(e);
    setTimeout(() => {
      if (!formikContext.touched[e.target.name]) {
        formikContext.setFieldTouched([e.target.name], true);
        document.getElementById(e.target.name).focus();
      }
    }, 100);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    switch (e.target.name) {
      case 'dob_day':
        changeDobDay(e, months);
        break;
      case 'dob_month':
        changeDobMonth(e, months);
        break;
      case 'dob_year':
        changeDobYear(e, months);
        break;
      default:
    }
  };
  const inputHandleChange = (e) => {
    handleChange(e);
    setTimeout(() => {
      if (!formikContext.touched[e.target.name]) {
        formikContext.setFieldTouched([e.target.name], true);
        if (document.getElementById(e.target.name)) {
          document.getElementById(e.target.name).focus();
        }
      }
    }, 100);
    if (
      e.target.name === MOBILE_NUMBER ||
      e.target.name === ALT_MOBILE_NUMBER ||
      e.target.name === OTHER_MOBILE_NUMBER ||
      e.target.name === OTHER_ALT_MOBILE_NUMBER
    ) {
      if (e.target.value.match(/^\d{7}$/)) {
        formikContext.setFieldValue(e.target.name, formatPhoneNumber(e.target.value));
      } else {
        const value = e.target.value.replace(/-/g, '');
        formikContext.setFieldValue(e.target.name, value);
      }
      setTimeout(() => {
        formikContext.setFieldTouched([e.target.name], true);
      }, 100);
    }
    if (
      e.target.name === ALT_MOBILE_NUM_AREA_CODE &&
      e.target.value === '' &&
      values.altMobileNumber !== ''
    ) {
      formikContext.setFieldTouched([e.target.name], true);
    }
  };
  const outlinedInputsError = {
    background: '#FD00000D',
  };
  return (
    <div>
      <Grid style={{ width: '100%' }}>
        <Grid item container xs={12} style={{ margin: '10px 0' }}>
          <Grid item xs={12} style={{ margin: '12px 0' }}>
            <FormLabel className={classes.main_label}>
              {t(TELL_US_ABOUT_YOURSELF, {
                defaultValue: TELL_US_ABOUT_YOURSELF,
                nsSeparator: '|',
              })}
            </FormLabel>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={CommonCssStyles.fields_grid}>
          <Grid container item xs={12} md={4} style={{ padding: '0 7px' }}>
            <Grid
              item
              xs={12}
              md={10}
              className={
                (errors.firstName && touched.firstName) ||
                (stepBackFlag && errors.firstName && values.firstName !== '')
                  ? CommonCssStyles.ErrorEnableGrid
                  : {}
              }
            >
              <InputLabel
                htmlFor="firstName"
                required
                aria-label={`${t(TELL_US_ABOUT_YOURSELF, {
                  defaultValue: TELL_US_ABOUT_YOURSELF,
                  nsSeparator: '|',
                })} ${t(FIRST_NAME, { defaultValue: FIRST_NAME })} ${t(REQUIRED, {
                  defaultValue: REQUIRED,
                })}`}
                className={CommonCssStyles.label_title}
              >
                {t(FIRST_NAME, { defaultValue: FIRST_NAME })}:
              </InputLabel>
              <OutlinedInput
                id="firstName"
                name="firstName"
                className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
                placeholder={t(ENTER_FIRST_NAME, { defaultValue: ENTER_FIRST_NAME })}
                fullWidth
                inputProps={{ maxLength: 10, 'data-testid': 'firstName_testid' }}
                value={values.firstName}
                onBlur={handleBlur}
                onChange={inputHandleChange}
                autoComplete="off"
                style={
                  (errors.firstName && touched.firstName) ||
                  (stepBackFlag && errors.firstName && values.firstName !== '')
                    ? outlinedInputsError
                    : {}
                }
              />
              <span className={CommonCssStyles.error_message_input}>
                {((errors.firstName && touched.firstName) ||
                  (stepBackFlag && errors.firstName && values.firstName !== '')) && (
                  <span role="alert" tabIndex="-1">
                    {t(errors.firstName, { defaultValue: errors.firstName })}
                  </span>
                )}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: '0 7px' }}>
            <Grid
              item
              xs={12}
              md={6}
              className={
                (errors.middleName && touched.middleName) ||
                (stepBackFlag && errors.middleName && values.middleName !== '')
                  ? CommonCssStyles.ErrorEnableGrid
                  : {}
              }
            >
              <InputLabel
                htmlFor="middleName"
                aria-label={`${t(MIDDLE_INITINAL, { defaultValue: MIDDLE_INITINAL })} ${t(
                  REQUIRED,
                  { defaultValue: REQUIRED }
                )}`}
                className={CommonCssStyles.label_title}
              >
                {t(MIDDLE_INITINAL, { defaultValue: MIDDLE_INITINAL })}:
              </InputLabel>
              <OutlinedInput
                id="middleName"
                name="middleName"
                className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
                placeholder={t(ENTER_MIDDLE_INITINAL, { defaultValue: ENTER_MIDDLE_INITINAL })}
                fullWidth
                inputProps={{ maxLength: 1 }}
                value={values.middleName}
                onBlur={handleBlur}
                onChange={inputHandleChange}
                autoComplete="off"
                style={
                  (errors.middleName && touched.middleName) ||
                  (stepBackFlag && errors.middleName && values.middleName !== '')
                    ? outlinedInputsError
                    : {}
                }
              />
              <span className={CommonCssStyles.error_message_input}>
                {((errors.middleName && touched.middleName) ||
                  (stepBackFlag && errors.middleName && values.middleName !== '')) && (
                  <span role="alert" tabIndex="-1">
                    {t(errors.middleName, { defaultValue: errors.middleName })}
                  </span>
                )}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: '0 7px' }}>
            <Grid
              item
              xs={12}
              md={12}
              lg={10}
              className={
                (errors.lastName && touched.lastName) ||
                (stepBackFlag && errors.lastName && values.lastName !== '')
                  ? CommonCssStyles.ErrorEnableGrid
                  : {}
              }
            >
              <InputLabel
                htmlFor="lastName"
                required
                aria-label={`${t(LAST_NAME, { defaultValue: LAST_NAME })} ${t(REQUIRED, {
                  defaultValue: REQUIRED,
                })}`}
                className={CommonCssStyles.label_title}
              >
                {t(LAST_NAME, { defaultValue: LAST_NAME })}:
              </InputLabel>
              <OutlinedInput
                id="lastName"
                name="lastName"
                className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
                placeholder={t(ENTER_LAST_NAME, { defaultValue: ENTER_LAST_NAME })}
                fullWidth
                inputProps={{ maxLength: 15 }}
                value={values.lastName}
                onBlur={handleBlur}
                onChange={inputHandleChange}
                autoComplete="off"
                style={
                  (errors.lastName && touched.lastName) ||
                  (stepBackFlag && errors.lastName && values.lastName !== '')
                    ? outlinedInputsError
                    : {}
                }
              />
              <span className={CommonCssStyles.error_message_input}>
                {((errors.lastName && touched.lastName) ||
                  (stepBackFlag && errors.lastName && values.lastName !== '')) && (
                  <span role="alert" tabIndex="-1">
                    {t(errors.lastName, { defaultValue: errors.lastName })}
                  </span>
                )}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={CommonCssStyles.fields_grid}>
          <Grid container item xs={12} md={4} style={{ padding: '0 7px' }}>
            <Grid
              item
              xs={12}
              md={10}
              className={
                (errors.mothersLastName && touched.mothersLastName) ||
                (stepBackFlag && errors.mothersLastName && values.mothersLastName !== '')
                  ? CommonCssStyles.ErrorEnableGrid
                  : {}
              }
            >
              <InputLabel
                htmlFor="mothersLastName"
                aria-label={`${t(MOTHERS_LAST_NAME, { defaultValue: MOTHERS_LAST_NAME })}`}
                className={CommonCssStyles.label_title}
              >
                {t(MOTHERS_LAST_NAME, { defaultValue: MOTHERS_LAST_NAME })}:
              </InputLabel>
              <OutlinedInput
                id="mothersLastName"
                name="mothersLastName"
                className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
                placeholder={t(ENTER_SECOND_LAST_NAME, {
                  defaultValue: ENTER_SECOND_LAST_NAME,
                })}
                fullWidth
                inputProps={{ maxLength: 15 }}
                value={values.mothersLastName}
                onBlur={handleBlur}
                onChange={inputHandleChange}
                autoComplete="off"
                style={
                  (errors.mothersLastName && touched.mothersLastName) ||
                  (stepBackFlag && errors.mothersLastName && values.mothersLastName !== '')
                    ? outlinedInputsError
                    : {}
                }
              />
              <span className={CommonCssStyles.error_message_input}>
                {((errors.mothersLastName && touched.mothersLastName) ||
                  (stepBackFlag && errors.mothersLastName && values.mothersLastName !== '')) && (
                  <span role="alert" tabIndex="-1">
                    {t(errors.mothersLastName, { defaultValue: errors.mothersLastName })}
                  </span>
                )}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: '0 7px' }}>
            <Grid
              item
              xs={12}
              md={10}
              className={
                (errors.loanNumber && touched.loanNumber) ||
                (stepBackFlag && errors.loanNumber && values.loanNumber !== '')
                  ? CommonCssStyles.ErrorEnableGrid
                  : {}
              }
            >
              <InputLabel
                htmlFor="loanNumber"
                required={values.loanNumber ? true : !values.dob_date}
                aria-label={`${t(LOAN_NUMBER, { defaultValue: LOAN_NUMBER })} ${t(REQUIRED, {
                  defaultValue: REQUIRED,
                })}`}
                className={CommonCssStyles.label_title}
              >
                {t(LOAN_NUMBER, { defaultValue: LOAN_NUMBER })}:
              </InputLabel>
              <OutlinedInput
                id="loanNumber"
                name="loanNumber"
                className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.input_disabled_style}`}
                placeholder={t(ENTER_LOAN_NUMBER, { defaultValue: ENTER_LOAN_NUMBER })}
                fullWidth
                inputProps={{
                  inputMode: isMobile || isTablet ? 'numeric' : 'text',
                }}
                value={values.loanNumber}
                onBlur={handleBlur}
                onChange={inputHandleChange}
                autoComplete="off"
                style={
                  (errors.loanNumber && touched.loanNumber) ||
                  (stepBackFlag && errors.loanNumber && values.loanNumber !== '')
                    ? outlinedInputsError
                    : {}
                }
              />
              <span className={CommonCssStyles.error_message_input}>
                {((errors.loanNumber && touched.loanNumber) ||
                  (stepBackFlag && errors.loanNumber && values.loanNumber !== '')) && (
                  <span role="alert" tabIndex="-1">
                    {t(errors.loanNumber, { defaultValue: errors.loanNumber })}
                  </span>
                )}
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: '0 7px' }}>
            <Grid
              item
              xs={12}
              md={12}
              lg={10}
              className={
                errors.socialSecurityNumber && touched.socialSecurityNumber
                  ? CommonCssStyles.ErrorEnableGrid
                  : {}
              }
            >
              <SSNMask />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={CommonCssStyles.fields_grid}>
          <Grid container item xs={12} md={4} style={{ padding: '0 7px' }}>
            <Grid
              item
              xs={12}
              md={10}
              className={
                (errors.mobileNumber && touched.mobileNumber) ||
                (errors.mobileNumberAreacode && touched.mobileNumberAreacode) ||
                (stepBackFlag && errors.mobileNumber && values.mobileNumber !== '') ||
                (stepBackFlag && errors.mobileNumberAreacode && values.mobileNumberAreacode !== '')
                  ? CommonCssStyles.ErrorEnableGrid
                  : {}
              }
            >
              <InputLabel
                htmlFor="mobileNumber"
                required
                aria-label={`${t(MOBILE_NUMBER_CAPS, { defaultValue: MOBILE_NUMBER_CAPS })} ${t(
                  REQUIRED,
                  { defaultValue: REQUIRED }
                )}`}
                className={CommonCssStyles.label_title}
              >
                {t(MOBILE, { defaultValue: MOBILE })}:
              </InputLabel>

              <OutlinedInput
                id="mobileNumber"
                name="mobileNumber"
                className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.mobileFormatStyle}`}
                placeholder={t(ENTER_MOBILE_NUMBER, { defaultValue: ENTER_MOBILE_NUMBER })}
                fullWidth
                autoComplete="off"
                inputProps={{
                  maxLength: isMobile && mobileVendor === 'Apple' ? 8 : 7,
                  inputMode: isMobile || isTablet ? 'numeric' : 'text',
                }}
                value={values.mobileNumber}
                onBlur={handleBlur}
                onChange={inputHandleChange}
                style={
                  (errors.mobileNumber && touched.mobileNumber) ||
                  (errors.mobileNumberAreacode && touched.mobileNumberAreacode) ||
                  (stepBackFlag && errors.mobileNumber && values.mobileNumber !== '') ||
                  (stepBackFlag &&
                    errors.mobileNumberAreacode &&
                    values.mobileNumberAreacode !== '')
                    ? outlinedInputsError
                    : {}
                }
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ marginRight: '0', minWidth: '70px' }}
                    className="personal_mo_number"
                  >
                    <OutlinedInput
                      inputProps={{
                        maxLength: 3,
                        inputMode: isMobile || isTablet ? 'numeric' : 'text',
                        'aria-label': `${t(AREA_CODE, { defaultValue: AREA_CODE })} ${t(REQUIRED, {
                          defaultValue: REQUIRED,
                        })}`,
                      }}
                      id="mobileNumberAreacode"
                      name="mobileNumberAreacode"
                      style={{ width: '70px' }}
                      autoComplete="off"
                      value={values.mobileNumberAreacode}
                      onBlur={handleBlur}
                      onChange={inputHandleChange}
                      className={CommonCssStyles.area_code_input}
                    />
                  </InputAdornment>
                }
              />
              <span className={CommonCssStyles.error_message_input}>
                {((errors.mobileNumber && touched.mobileNumber) ||
                  (errors.mobileNumberAreacode && touched.mobileNumberAreacode) ||
                  (stepBackFlag && errors.mobileNumber && values.mobileNumber !== '') ||
                  (stepBackFlag &&
                    errors.mobileNumberAreacode &&
                    values.mobileNumberAreacode !== '')) && (
                  <span role="alert" tabIndex="-1">
                    {t(errors.mobileNumberAreacode, {
                      defaultValue: errors.mobileNumberAreacode,
                    })}
                    {t(errors.mobileNumber, {
                      defaultValue: errors.mobileNumber,
                    })}
                  </span>
                )}
              </span>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={4} style={{ padding: '0 7px' }}>
            <Grid
              item
              xs={12}
              md={10}
              className={
                (errors.altMobileNumber && touched.altMobileNumber) ||
                (errors.altMobileNumberAreacode && touched.altMobileNumberAreacode) ||
                (stepBackFlag && errors.altMobileNumber && values.altMobileNumber !== '') ||
                (stepBackFlag &&
                  errors.altMobileNumberAreacode &&
                  values.altMobileNumberAreacode !== '')
                  ? CommonCssStyles.ErrorEnableGrid
                  : {}
              }
            >
              <InputLabel
                htmlFor="altMobileNumber"
                aria-label={`${t(ALTERNATE_PHONE, { defaultValue: ALTERNATE_PHONE })}`}
                className={CommonCssStyles.label_title}
              >
                {t(ALTERNATE_PHONE, { defaultValue: ALTERNATE_PHONE })}:
              </InputLabel>
              <OutlinedInput
                id="altMobileNumber"
                name="altMobileNumber"
                className={`${CommonCssStyles.outlined_inputs} ${CommonCssStyles.mobileFormatStyle}`}
                placeholder={t(ENTER_MOBILE_NUMBER, { defaultValue: ENTER_MOBILE_NUMBER })}
                fullWidth
                autoComplete="off"
                inputProps={{
                  maxLength: isMobile && mobileVendor === 'Apple' ? 8 : 7,
                  inputMode: isMobile || isTablet ? 'numeric' : 'text',
                }}
                value={values.altMobileNumber}
                onBlur={handleBlur}
                onChange={inputHandleChange}
                style={
                  (errors.altMobileNumber && touched.altMobileNumber) ||
                  (errors.altMobileNumberAreacode && touched.altMobileNumberAreacode) ||
                  (stepBackFlag && errors.altMobileNumber && values.altMobileNumber !== '') ||
                  (stepBackFlag &&
                    errors.altMobileNumberAreacode &&
                    values.altMobileNumberAreacode !== '')
                    ? outlinedInputsError
                    : {}
                }
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ marginRight: '0', minWidth: '70px' }}
                    className="personal_mo_number"
                  >
                    <OutlinedInput
                      inputProps={{
                        maxLength: 3,
                        inputMode: isMobile || isTablet ? 'numeric' : 'text',
                        'aria-label': `${t(AREA_CODE, { defaultValue: AREA_CODE })}`,
                      }}
                      id="altMobileNumberAreacode"
                      name="altMobileNumberAreacode"
                      style={{ width: '70px' }}
                      autoComplete="off"
                      value={values.altMobileNumberAreacode}
                      onBlur={handleBlur}
                      onChange={inputHandleChange}
                      className={CommonCssStyles.area_code_input}
                    />
                  </InputAdornment>
                }
              />
              <span className={CommonCssStyles.error_message_input}>
                {((errors.altMobileNumber && touched.altMobileNumber) ||
                  (errors.altMobileNumberAreacode && touched.altMobileNumberAreacode) ||
                  (stepBackFlag && errors.altMobileNumber && values.altMobileNumber !== '') ||
                  (stepBackFlag &&
                    errors.altMobileNumberAreacode &&
                    values.altMobileNumberAreacode !== '')) && (
                  <span role="alert" tabIndex="-1">
                    {t(errors.altMobileNumberAreacode, {
                      defaultValue: errors.altMobileNumberAreacode,
                    })}
                    {t(errors.altMobileNumber, {
                      defaultValue: errors.altMobileNumber,
                    })}
                  </span>
                )}
              </span>
            </Grid>
          </Grid>
          {((values.loanNumber === '' && touched.loanNumber && errors.loanNumber !== '') ||
            (values.dob_date !== '' && values.loanNumber === '')) && (
            <DateInput
              open={open}
              englishBot={englishBot}
              handleClose={handleClose}
              stepBackFlag={stepBackFlag}
              dateInvalid={dateInvalid}
              DOBInvalid={DOBInvalid}
              handleDateOfBirthChangeDropdown={handleDateOfBirthChangeDropdown}
              handleClickOpen={handleClickOpen}
              handleDateOfBirthChange={handleDateOfBirthChange}
            />
          )}
        </Grid>
        <Grid item container xs={12} style={{ margin: '10px 0' }}>
          <Grid item xs={12} style={{ margin: '12px 0' }}>
            <FormLabel className={classes.main_label}>
              {t(LET_US_KNOW_EMAIL, {
                defaultValue: LET_US_KNOW_EMAIL,
              })}
              :
            </FormLabel>
          </Grid>
        </Grid>
        <EmailInput stepBackFlag={stepBackFlag} inputHandleChange={inputHandleChange} />
      </Grid>
    </div>
  );
}
StepTwo.propTypes = {
  englishBot: PropTypes.bool,
  stepBackFlag: PropTypes.string,
};
export default StepTwo;
