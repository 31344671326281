import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Tooltip, OutlinedInput } from '@material-ui/core';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import { isMobile, isDesktop } from 'react-device-detect';
import { PropTypes } from 'prop-types';
import { Row, IconButton, SendButton, TextComposer, Fill, TextInput } from '@livechat/ui-kit';
import { Autocomplete } from '@material-ui/lab';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { ChatWrapperCss } from '../utils/WrapperCss';
import { useStyles } from '../balance-cancellation/OverrideStyles';
import spoken from '../audio/spoken';
import {
  ATTACHMENT_FILE,
  DESTINATION,
  TYPE_A_MESSAGE_ENG,
  TYPE_A_MESSAGE_ESP,
  UPLOAD_DOC_HERE,
} from '../../../Constants';

const useChatWrapperCss = ChatWrapperCss();
const ChatFooter = (props) => {
  const { t } = useTranslation();
  const WrapperCss = useChatWrapperCss();
  const classes = useStyles();
  const [lists, setLists] = useState([]);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [speechOn, setSpeechOn] = useState(false);
  const {
    englishBot,
    handleChange,
    addMessages,
    textComposerKey,
    setTextComposerKey,
    waitingForLiveAgent,
    isFeedbackProcess,
    runningAWSConnect,
    liveAgentJoined,
    sendBtnActive,
    handleSendButtonClick,
    defaultTextContent,
    fileHandleChange,
    hiddenFileInput,
    maxLength,
    numericKeyboard,
    slotToElicit,
    setSendBtnActive,
    setDefaultTextContent,
    autoCompleteValue,
    setAutoCompleteValue,
    showAutoComplete,
    setShowAutoComplete,
  } = props;
  const handleInputChange = (value) => {
    setAutoCompleteValue(value);
  };

  useEffect(() => {
    if (speechOn) {
      SpeechRecognition.startListening({ language: englishBot ? 'en-US' : 'es-US' });
    } else {
      SpeechRecognition.stopListening();
      if (transcript !== '') {
        addMessages(transcript);
        resetTranscript();
      }
    }
  }, [speechOn]);

  const speechHandler = (e) => {
    setSpeechOn((p) => !p);
    // spoken.recognition.language = englishBot ? 'en-US' : 'en-ES';
    // spoken
    //   .listen()
    //   ?.then((transcript) => {
    //     addMessages(transcript);
    //   })
    //   .catch();
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  return (
    <div className={WrapperCss.textComposerWrapper}>
      <TextComposer
        onChange={handleChange}
        data-testid="testTextComposer"
        key={textComposerKey}
        active={waitingForLiveAgent ? false : sendBtnActive}
        onSend={handleSendButtonClick}
        defaultValue={defaultTextContent}
      >
        <Row align="center">
          {isFeedbackProcess && runningAWSConnect && liveAgentJoined && (
            <IconButton
              onClick={handleClick}
              aria-label={ATTACHMENT_FILE}
              tabIndex="105"
              data-testid="buttonFooterTestId"
            >
              <div className={WrapperCss.textareaWrapper}>
                <Tooltip
                  title={t(UPLOAD_DOC_HERE, { defaultValue: UPLOAD_DOC_HERE })}
                  arrow
                  placement="top-start"
                  leaveTouchDelay={3000}
                  enterTouchDelay={50}
                  classes={{
                    tooltip: classes.tooltipUpload,
                    arrow: classes.arrowUpload,
                  }}
                >
                  <AttachFileRoundedIcon className={WrapperCss.attachIcon} />
                </Tooltip>
              </div>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={fileHandleChange}
                className={WrapperCss.hidden_design}
                data-testid="inputFileTestid"
              />
            </IconButton>
          )}

          <Fill className={WrapperCss.fill_design}>
            {numericKeyboard && isMobile && (
              <TextInput
                type="number"
                min="0"
                inputmode="numeric"
                pattern="[0-9]*"
                rows="2"
                cols="10"
                tabIndex="103"
                maxLength={maxLength}
                className="text-area"
                placeholder={englishBot ? TYPE_A_MESSAGE_ENG : TYPE_A_MESSAGE_ESP}
                id="messageText"
                name="messageText"
                value=""
              />
            )}
            {slotToElicit === DESTINATION && showAutoComplete && (
              <Autocomplete
                id="autocompleteChatBot"
                data-testid="autocomplete_id"
                options={lists}
                freeSolo
                value={autoCompleteValue}
                inputValue={autoCompleteValue}
                onChange={(event, selectedValue) => {
                  if (event) {
                    handleInputChange(selectedValue);
                    localStorage.setItem('textContent', selectedValue);
                    setDefaultTextContent(selectedValue || '');
                    setSendBtnActive(true);
                    document.getElementById('send-btn').disabled = false;
                    if (selectedValue) {
                      setShowAutoComplete(false);
                    } else {
                      setShowAutoComplete(true);
                      setAutoCompleteValue('');
                    }
                    setTextComposerKey((prev) => prev * 2);
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (event) {
                    handleInputChange(newInputValue);
                    localStorage.setItem('textContent', newInputValue);
                    setDefaultTextContent(newInputValue || '');
                    document.getElementById('send-btn').disabled = false;
                  }
                }}
                filterOptions={(options) =>
                  options.filter((option) =>
                    option.toLowerCase().includes(autoCompleteValue.toLowerCase())
                  )
                }
                renderInput={(params) => {
                  params.inputProps.maxLength = 50;
                  return (
                    <OutlinedInput
                      inputProps={params.inputProps}
                      ref={params.InputProps.ref}
                      autoFocus
                      rows="2"
                      cols="10"
                      tabIndex="103"
                      maxLength={maxLength}
                      className="text-area custom-textarea"
                      placeholder={englishBot ? TYPE_A_MESSAGE_ENG : TYPE_A_MESSAGE_ESP}
                      id="messageText2"
                      name="messageText2"
                      value={autoCompleteValue}
                      style={{ border: 'none' }}
                    />
                  );
                }}
              />
            )}
            {!showAutoComplete && (isDesktop || (isMobile && !numericKeyboard)) && (
              <TextInput
                rows="2"
                cols="10"
                tabIndex="103"
                maxLength={maxLength}
                className="text-area"
                placeholder={englishBot ? TYPE_A_MESSAGE_ENG : TYPE_A_MESSAGE_ESP}
                id="messageText"
                name="messageText"
                value=""
                defaultValue={defaultTextContent}
              />
            )}
          </Fill>
          <SendButton
            className={WrapperCss.sendButton}
            id="send-btn"
            aria-label="send"
            tabIndex="104"
            // disabled={!sendBtnActive}
          />
          {/* <IconButton color="#F5821F" onClick={speechHandler} data-testid="speechHandlerTestId">
            <i className="material-icons" style={{ color: '#F5821F' }}>
              mic_none
            </i>
          </IconButton> */}
        </Row>
      </TextComposer>
    </div>
  );
};

ChatFooter.propTypes = {
  englishBot: PropTypes.bool,
  handleChange: PropTypes.func,
  addMessages: PropTypes.func,
  textComposerKey: PropTypes.number,
  setTextComposerKey: PropTypes.func,
  waitingForLiveAgent: PropTypes.bool,
  isFeedbackProcess: PropTypes.bool,
  runningAWSConnect: PropTypes.bool,
  liveAgentJoined: PropTypes.bool,
  sendBtnActive: PropTypes.bool,
  handleSendButtonClick: PropTypes.func,
  defaultTextContent: PropTypes.string,
  fileHandleChange: PropTypes.func,
  hiddenFileInput: PropTypes.object,
  maxLength: PropTypes.number,
  numericKeyboard: PropTypes.bool,
  slotToElicit: PropTypes.string,
  setSendBtnActive: PropTypes.func,
  setDefaultTextContent: PropTypes.func,
  autoCompleteValue: PropTypes.string,
  setAutoCompleteValue: PropTypes.func,
  showAutoComplete: PropTypes.func,
  setShowAutoComplete: PropTypes.func,
};

export default ChatFooter;
