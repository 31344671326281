import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { IconButton } from '@livechat/ui-kit';
import { TRY_SOMETHING_ELSE_ENG, TRY_SOMETHING_ELSE_ESP } from '../../../Constants';

const AgentBusyFlow = (props) => {
  const { t } = useTranslation();
  const { buttonMessageOnbackToLexTrigger, pegaConnectTrigger, liveAgentTrigger, englishBot } =
    props;
  const defaultContent = englishBot ? 'Yes' : 'Sí';
  const btn2response = englishBot ? TRY_SOMETHING_ELSE_ENG : TRY_SOMETHING_ELSE_ESP;
  const backToChatbot = () => {
    pegaConnectTrigger();
  };
  const showWaitingTime = () => {
    liveAgentTrigger();
    setTimeout(() => {
      buttonMessageOnbackToLexTrigger(btn2response, false);
    }, 2000);
  };
  return (
    <div
      className="quick-replies"
      style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
    >
      <IconButton onClick={backToChatbot} tabIndex="102" data-testid="backToChatbot_testid">
        {defaultContent}
      </IconButton>
      <IconButton onClick={showWaitingTime} tabIndex="102" data-testid="showWaitingTime_testid">
        {t('No')}
      </IconButton>
    </div>
  );
};
AgentBusyFlow.propTypes = {
  englishBot: PropTypes.bool,
  buttonMessageOnbackToLexTrigger: PropTypes.func,
  pegaConnectTrigger: PropTypes.func,
  liveAgentTrigger: PropTypes.func,
};
export default AgentBusyFlow;
