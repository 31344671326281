import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Grid, Button, SvgIcon } from '@material-ui/core';

import styles from './FileUpload.module.css';
import { ReactComponent as closeIcon } from '../images/close.svg';
import { ReactComponent as checkmarkIcon } from '../images/checkmark.svg';
import { ReactComponent as backIcon } from '../images/back.svg';
import { ReactComponent as infoIcon } from '../images/info icon.svg';
import { FileProgressBar } from './FileProgressBar';

const FileUpload = ({ uploadDocuments, cancelUploadDocuments }) => {
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showProgress, setShowProgress] = useState([]);
  const [showProgressError, setShowProgressError] = useState({});
  const [removedAll, setRemovedAll] = useState(false);
  const [removeFilesList, setRemoveFilesList] = useState([]);
  const [selectedFileWithRemovedData, setSelectedFileWithRemovedData] = useState({});

  const scrollToBottom = () => {
    const messageContainer = document.getElementById('messageContainer');
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight - messageContainer.clientHeight;
    }
  };

  const onFileSelect = async (e) => {
    const file = e.target.files[0];

    if (file.size > 10 * 1024 * 1024 || file.type !== 'application/pdf') {
      const obj = { ...showProgressError };
      obj[showProgress.length] = {
        status: true,
        message:
          file.type !== 'application/pdf'
            ? `${t('Supported file type', {
                defaultValue: 'Supported file type',
              })}: PDF`
            : t('Uploaded file exceeds the max size', {
                defaultValue: 'Uploaded file exceeds the max size',
              }),
      };
      setShowProgressError(obj);

      const progressArr = [...showProgress];
      progressArr.push(true);
      setShowProgress(progressArr);
      const arr = [...selectedFiles];
      arr.push(false);
      setTimeout(() => {
        setSelectedFiles(arr);
        scrollToBottom();
      }, 1000);
    } else {
      const arr = [...selectedFiles];
      arr.push(file);

      const obj = { ...showProgressError };
      obj[showProgress.length] = {
        status: false,
        message: '',
      };
      setShowProgressError(obj);

      const progressArr = [...showProgress];
      progressArr.push(true);
      setShowProgress(progressArr);

      setTimeout(() => {
        setSelectedFiles(arr);
        const newArr = [...progressArr];
        newArr[progressArr.length - 1] = false;
        setShowProgress(newArr);
        scrollToBottom();
      }, 1000);
    }
  };

  const handleRemoveAll = () => {
    const arr = [...selectedFiles];
    setRemoveFilesList(arr);
    setRemovedAll(true);
    scrollToBottom();
  };

  const handleUndoAll = () => {
    const arr = [...removeFilesList];
    setSelectedFiles(arr);
    setRemovedAll(false);
    scrollToBottom();
  };

  const sliceFromArr = (items, index, updateItems) => {
    const arr = [...items];
    arr.splice(index, 1);
    updateItems(arr);
  };

  const handleSingleFileRemove = (index) => {
    const obj = { ...selectedFileWithRemovedData };
    obj[index] = {
      undo: true,
      data: selectedFiles[index],
    };
    setSelectedFileWithRemovedData(obj);
    scrollToBottom();
  };

  const handleSinglefileUndoRemove = (e, index) => {
    const arr = [...selectedFiles];
    arr[index] = selectedFileWithRemovedData[index].data;
    setSelectedFiles(arr);

    const obj = { ...selectedFileWithRemovedData };
    obj[index] = {};
    setSelectedFileWithRemovedData(obj);
    scrollToBottom();
    e.stopPropagation();
  };

  const handleSinglefileUndoRemovee = (e, index) => {
    const obj = { ...selectedFileWithRemovedData };
    delete obj[index];
    setSelectedFileWithRemovedData(obj);
    sliceFromArr(showProgress, index, setShowProgress);
    sliceFromArr(selectedFiles, index, setSelectedFiles);
    scrollToBottom();

    e.stopPropagation();
  };

  const handleRemoveAllUndoFiles = (e) => {
    setRemoveFilesList([]);
    setSelectedFiles([]);
    setRemovedAll(false);
    setSelectedFileWithRemovedData({});
    setShowProgressError({});
    setShowProgress([]);
    scrollToBottom();

    e.stopPropagation();
  };

  const fileCurrentRemove = (e, index) => {
    const obj = { ...showProgressError };
    delete obj[index];
    setShowProgressError(obj);

    sliceFromArr(showProgress, index, setShowProgress);
    sliceFromArr(selectedFiles, index, setSelectedFiles);
    scrollToBottom();
  };

  return (
    <div
      ref={wrapperRef}
      data-testid="drag_testId"
      className={`dragFileUpdate ${styles['drop-file-input']}`}
    >
      <div className={styles.fileUploadContainer}>
        <div className={styles.dropFileInputLabel}>
          <div className={styles.dropFileInputLabel_inner}>
            <div className={styles.drag}>
              {t('Please Upload Documents', {
                defaultValue: 'Please Upload Documents',
              })}
            </div>
            <Button
              type="submit"
              data-testid="submittype_testid"
              variant="contained"
              id={styles.selectFiles}
              className={showProgress.length > 4 ? styles.disabledFileField : ''}
              disabled={showProgress.length > 4}
            >
              {t('Select Files', {
                defaultValue: 'Select Files',
              })}
              <input
                name="dragInput"
                type="file"
                value=""
                accept="application/pdf"
                data-testid="dragInput_testid"
                className={styles.activeButton}
                onChange={onFileSelect}
                aria-label={t('Please Upload Documents', {
                  defaultValue: 'Please Upload Documents',
                })}
              />
            </Button>
          </div>
        </div>
        <div className={styles.notediv}>
          <div className={styles.validation}>
            <span aria-label="info icon" className={styles.infoIconWrapper}>
              <SvgIcon
                aria-label="info"
                role="graphic"
                titleAccess="info Icon"
                component={infoIcon}
              />
              {t('Upload a maximum of 5 documents of 10MB each', {
                defaultValue: 'Upload a maximum of 5 documents of 10MB each',
              })}
            </span>
          </div>
        </div>
      </div>
      {!removedAll &&
        showProgress.map((current, index) =>
          showProgress[index] ? (
            <div className={styles.progressBarWrapper}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                key={current.name}
              >
                <Grid item xs={10}>
                  <FileProgressBar
                    errorMessage={showProgressError[index]?.message || ''}
                    error={showProgressError[index]?.status || false}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1} className={styles.progressRemoveWrapper}>
                  <span
                    className={styles.removeButton}
                    tabIndex="0"
                    aria-label={selectedFiles[index]?.name}
                    data-testid="removeCurrentButton_testId"
                    onClick={(e) => fileCurrentRemove(e, index)}
                  >
                    <SvgIcon
                      aria-label="close"
                      role="graphic"
                      titleAccess="close Icon"
                      className={styles.progressCloseSvg}
                      component={closeIcon}
                    />
                  </span>
                </Grid>
              </Grid>
            </div>
          ) : selectedFileWithRemovedData[index]?.undo ? (
            <Grid
              container
              className={styles.undoAllWrapper}
              onClick={(e) => handleSinglefileUndoRemove(e, index)}
            >
              <Grid item xs={1} className={styles.pointer}>
                <SvgIcon
                  aria-label="back"
                  role="graphic"
                  titleAccess="back Icon"
                  className={styles.svg}
                  component={backIcon}
                />
              </Grid>
              <Grid item xs={10} className={styles.pointer}>
                {t('Undo Removed File', {
                  defaultValue: 'Undo Removed File',
                })}
              </Grid>
              <Grid item xs={1}>
                <span tabIndex="0" data-testid="removeButton_testId" className={styles.pointer}>
                  <SvgIcon
                    aria-label="close"
                    role="graphic"
                    titleAccess="close Icon"
                    className={styles.svg}
                    component={closeIcon}
                    onClick={(e) => handleSinglefileUndoRemovee(e, index)}
                  />
                </span>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              key={current.name}
              className={styles.fileListWrapper}
            >
              <Grid item xs={1}>
                <SvgIcon
                  aria-label="checkmark"
                  role="graphic"
                  titleAccess="Checkmark Icon"
                  className={styles.svg}
                  component={checkmarkIcon}
                />
              </Grid>
              <Grid
                item
                xs={9}
                className={styles['drop-file-preview__item__info']}
                sx={{ padding: '10px' }}
              >
                <div className={styles.fileName}>{selectedFiles[index]?.name}</div>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <span
                  className={styles.removeButton}
                  tabIndex="0"
                  aria-label={selectedFiles[index]?.name}
                  data-testid="removeButton_testId"
                  onClick={() => handleSingleFileRemove(index)}
                >
                  <SvgIcon
                    aria-label="close"
                    role="graphic"
                    titleAccess="close Icon"
                    className={styles.svg}
                    component={closeIcon}
                  />
                </span>
              </Grid>
            </Grid>
          )
        )}
      {removedAll && (
        <Grid container className={styles.undoAllWrapper} onClick={handleUndoAll}>
          <Grid item xs={1} className={styles.pointer}>
            <SvgIcon
              aria-label="back"
              role="graphic"
              titleAccess="back Icon"
              className={styles.svg}
              component={backIcon}
            />
          </Grid>
          <Grid item xs={9} className={styles.pointer}>
            {t('Undo Removed {num} Files', {
              defaultValue: 'Undo Removed {num} Files',
            }).replace('{num}', removeFilesList.length)}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1}>
            <span tabIndex="0" className={styles.pointer} data-testid="removeButton_testId">
              <SvgIcon
                aria-label="close"
                role="graphic"
                titleAccess="close Icon"
                className={styles.svg}
                component={closeIcon}
                onClick={handleRemoveAllUndoFiles}
              />
            </span>
          </Grid>
        </Grid>
      )}
      {selectedFiles.length > 0 && (
        <div className={styles.buttonsWrapper}>
          <Button
            className={`${styles.actionButtons}`}
            variant="outlined"
            type="button"
            onClick={() => cancelUploadDocuments()}
          >
            {t('Cancel', {
              defaultValue: 'Cancel',
            })}
          </Button>
          <Button
            className={`${styles.actionButtons}`}
            variant="outlined"
            type="button"
            onClick={handleRemoveAll}
          >
            {t('Remove All', {
              defaultValue: 'Remove All',
            })}
          </Button>
          <Button
            className={`${styles.containedActionButton}`}
            variant="contained"
            type="button"
            onClick={() => uploadDocuments(selectedFiles, selectedFileWithRemovedData, removedAll)}
          >
            {t('Confirm', {
              defaultValue: 'Confirm',
            })}
          </Button>
        </div>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  uploadDocuments: PropTypes.func,
  cancelUploadDocuments: PropTypes.func,
};

export default FileUpload;
